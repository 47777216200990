@use 'designSystem2022.module' as ds;

.onboardingDemoAppsWrapper {
  width: auto !important;

  .cardsWrapper {
    margin-top: var(--spacing-6);
    display: flex;
    gap: var(--spacing-8);

    .card {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-4);
      padding: var(--spacing-4);
      box-shadow: var(--shadows-card);
      min-width: 320px;
      justify-content: space-between;

      .uploadIcon {
        color: var(--theme-primary-default);
        font-size: 27px;
        font-weight: 300;
      }

      .cardHeader {
        display: flex;
        gap: var(--spacing-4);
        justify-content: center;

        .iconWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: var(--shadows-card);
          width: 72px;
          height: 72px;
          border-radius: 4px;
        }

        .sampleAppInfo {
          align-self: center;
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: var(--spacing-1);

          .sampleAppName {
            @include ds.BodyBody-1Semi-Bold;
            color: var(--text-light-high-emphasis);
          }

          .sampleAppTag {
            @include ds.CaptionDefault;
            color: var(--text-light-low-emphasis);
          }
        }
      }
    }

    .separatorWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: var(--spacing-2);

      .separator {
        height: 100%;
      }

      .separatorText {
        @include ds.CaptionDefault;
        color: var(--text-light-low-emphasis);
      }
    }
  }
}
