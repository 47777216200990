.boxesContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.displayNone {
  display: none;
}

.scanning {
  > div {
    &::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: 0;
      left: 10%;
      width: 80%;
      height: 8px;
      background: #6c75f1;
      border-radius: 8px;
      animation: animate_line 1.5s ease-in-out;
    }
  }

  :global(#boxes-container) {
    animation: animate_boxes 1.5s ease-in-out;
  }
}

@keyframes animate_line {
  0%,
  100% {
    top: 1%;
  }

  50% {
    top: 98%;
  }

  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes animate_boxes {
  0% {
    opacity: 0;
    clip-path: inset(100% 0 0 0);
  }

  50% {
    opacity: 0;
    clip-path: inset(100% 0 0 0);
  }

  100% {
    opacity: 1;
    clip-path: inset(0 0 0 0);
  }
}

.programmaticLock {
  padding: var(--spacing-2);
  background-color: var(--theme-warning-default);
  display: flex;
  gap: var(--spacing-4);
  justify-content: space-between;
  color: white;
  justify-content: center;
  align-items: center;
}
