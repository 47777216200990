@use 'designSystem2022.module' as ds;

.container {
  background-color: var(--surface-alternate);
  padding: 20px;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .screensContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    align-items: center;

    .screenContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: var(--spacing-2);

      .sideScreen,
      .middleScreen {
        padding: 0;
      }

      .middleScreen {
        margin: 0 var(--spacing-3);
      }
    }
  }
}
