/* Ant Design base not editable through theme.js */

.ant-checkbox-group .ant-checkbox-wrapper {
  margin-top: 10px;
}

.ant-checkbox-inner {
  border-radius: 2px;
}

.ant-input-group {
  height: inherit;
}

.ant-input-group .ant-input {
  height: inherit;
}

.ant-btn {
  line-height: 1.4;
  transition: 0.3s ease;
}

.ant-btn-clicked:after {
  animation: 0s !important;
}

.ant-btn .anticon {
  line-height: 0;
}

.ant-tooltip-inner {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.ant-switch.ant-switch-checked .ant-switch-handle {
  left: calc(55%);
}

.ant-select-arrow:before {
  font-family: waldo !important;
  font-size: 24px;
  content: '\e93c';
}

.ant-select-arrow {
  right: 5px;
}

.ant-slider .ant-slider-track,
.ant-slider:hover .ant-slider-track {
  background: #6c75f1;
}
.ant-slider .ant-slider-handle,
.ant-slider:hover .ant-slider-handle,
.ant-slider .ant-slider-handle:focus,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: white;
  width: 16px;
  height: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-top: -6px;
}

/* ant-select */
.ant-select-selector {
  outline: none;
  font-weight: 500;
  color: #989dbd;
}
.ant-select-selection--single {
  height: auto;
}
.ant-select-selection__rendered {
  font-size: 14px;
  line-height: 44px;
  overflow: auto;
}
.ant-select-dropdown-menu-item-active {
  background: white;
  color: #6c75f1 !important;
  font-weight: 500 !important;
}

.ant-select-sm .ant-select-selection {
  height: 32px;
}

.ant-select-sm .ant-select-selection__rendered {
  font-size: 14px;
  line-height: 28px;
}

.ant-select-sm .ant-select-dropdown-menu-item-active {
  background: white;
  color: #6c75f1 !important;
  font-weight: 500 !important;
}

.ant-dropdown-menu-item:hover {
  background: #f8f8fa;
}

/* antd badge status */
.ant-badge-status-text {
  margin-left: 4px;
}

/* ant notification GH style */
.ant-notification {
  width: auto;
}

.ant-radio-inner::after {
  top: 11px;
  left: 11px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
}

.ant-form-explain {
  font-style: italic;
  font-size: 12px;
}
