@use 'designSystem2022.module' as ds;

.createApp {
  display: flex;
  flex-direction: column;

  .buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: var(--spacing-8);

    .submitButton {
      flex: 1 0 auto;
    }
  }
}
