.itemsContainer {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  gap: 2px;

  .item {
    width: var(--spacing-2);
    min-width: var(--spacing-2);
    height: var(--spacing-4);
    min-height: var(--spacing-4);
    border-radius: 2px;
    user-select: none;
    color: rgba(255, 255, 255, 0.8);

    &.successItem {
      background-color: var(--theme-success-default);
    }

    &.failedItem {
      background-color: var(--theme-danger-default);
    }

    &.flakyItem {
      background-color: var(--theme-warning-default);
    }

    &.indeterminateItem {
      background-color: #ffc107;
    }
  }
}
