@use 'designSystem2022.module' as ds;

.banner {
  width: 100%;
  display: flex;
  gap: 12px;
  padding: 16px;
  border-radius: 4px;
  white-space: break-spaces;

  .title {
    @include ds.SubtitlesSubtitle-1;
    color: var(--text-light-high-emphasis);
  }
  .paragraph {
    @include ds.BodyBody-1Paragraph;
    color: var(--text-light-medium-emphasis);
  }
  .paragraphNoTitle {
    @include ds.BodyBody-2Regular;
    color: var(--text-light-high-emphasis);
  }
  &.basic {
    background-color: var(--grayscale-midnight-lighten-90);
    i {
      color: var(--theme-primary-default);
    }
  }
  &.info {
    background-color: var(--theme-primary-soft);
    i {
      color: var(--theme-primary-default);
    }
  }
  &.attention {
    background-color: var(--theme-attention-soft);
    i {
      color: var(--theme-attention-dark);
    }
  }
  &.warning {
    background-color: var(--theme-warning-soft);
    i {
      color: var(--theme-warning-dark);
    }
  }
  &.danger {
    background-color: var(--theme-danger-soft);
    i {
      color: var(--theme-danger-dark);
    }
  }
  &.success {
    background-color: var(--theme-success-soft);
    i {
      color: var(--theme-success-dark);
    }
  }

  &.dark {
    .title {
      color: var(--text-dark-high-emphasis);
    }
    .paragraph {
      color: var(--text-dark-medium-emphasis);
    }
    .paragraphNoTitle {
      color: var(--text-dark-high-emphasis);
    }
    &.basic {
      background-color: var(--special-opacity-smoke);
    }
    &.info {
      background-color: var(--special-opacity-primary);
    }
    &.attention {
      background-color: var(--special-opacity-attention);
    }
    &.warning {
      background-color: var(--special-opacity-warning);
    }
    &.danger {
      background-color: var(--special-opacity-danger);
    }
    &.success {
      background-color: var(--special-opacity-success);
    }
  }
}

.infoBannerNoTitle {
  padding: 8px;
  & > * {
    align-self: center;
  }
}

.textWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 8px;
}
.textAndButtonsWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  & > :first-child {
    flex: 1 1 auto;
  }
  & > :last-child {
    flex: 0 0 auto;
    align-self: end;
  }
}
.textAndButtonsWrapperNoTitle {
  flex-flow: row nowrap;
  & > :last-child {
    align-self: center;
  }
}
.buttonsWrapper {
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
}
