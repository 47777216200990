@use 'designSystem2022.module' as ds;

:global {
  .recharts-cartesian-axis-tick-value {
    @include ds.CaptionDefault;
    fill: var(--text-light-medium-emphasis);
  }
}

.legendContainer {
  @include ds.BodyBody-2Medium;
  color: var(--text-light-high-emphasis);
  display: flex;
  align-items: center;
  gap: 16px 24px;
  flex-wrap: wrap;
  margin-top: 24px;

  .legendItemContainer {
    display: flex;
    gap: 8px;
  }

  .square {
    margin-top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 1px;
  }

  .appNameContainer {
    display: flex;
    flex-direction: column;

    .appName {
      width: 130px;
      max-width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .appType {
      @include ds.BodyBody-2Regular;
      color: var(--text-light-low-emphasis);
    }
  }
}

.tooltipContainer {
  @include ds.BodyBody-2Paragraph;
  color: var(--text-light-high-emphasis);
  display: flex;
  gap: 0px;
  flex-direction: column;
  padding: 8px;
  border-radius: 4px;
  background-color: white;
  box-shadow: var(--shadows-hover);

  .tooltipLabel {
    @include ds.BodyBody-1Medium;
    margin-bottom: 8px;
  }
}
