@use 'designSystem2022.module' as ds;

.tabs {
  :global(.ant-tabs-tab) {
    flex: 1 1 auto;
    margin-bottom: 6px !important;
    padding: 0;

    &:not(:global(.ant-tabs-tab-disabled)):hover :global(.ant-tabs-tab-btn) {
      background-color: var(--special-hover-light);
      color: var(--theme-primary-default);
    }

    :global(.ant-tabs-tab-btn) {
      @include ds.BodyBody-1Semi-Bold;
      color: var(--text-light-medium-emphasis);
      border-radius: 4px;
      height: 30px;
      padding: 6px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      user-select: none;
      transition:
        background-color 0.3s ease,
        color 0.3s ease;
      width: 100%;
    }

    &:global(.ant-tabs-tab-disabled) :global(.ant-tabs-tab-btn) {
      color: var(--text-light-low-emphasis);
    }
  }

  :global(.ant-tabs-tab) + :global(.ant-tabs-tab) {
    margin-left: 0;
  }

  :global(.ant-tabs-tab-active) :global(.ant-tabs-tab-btn) {
    @include ds.BodyBody-1Semi-Bold;
    color: var(--theme-primary-default);
    text-shadow: none;
  }

  &.tabsSecondary {
    :global(.ant-tabs-tab) {
      &:not(:global(.ant-tabs-tab-disabled)):hover :global(.ant-tabs-tab-btn) {
        color: var(--text-light-high-emphasis);
      }

      :global(.ant-tabs-tab-btn) {
        @include ds.BodyBody-1Regular;
        color: var(--text-light-high-emphasis);
      }
    }

    :global(.ant-tabs-tab-active) :global(.ant-tabs-tab-btn) {
      @include ds.BodyBody-1Regular;
      color: var(--text-light-high-emphasis);
    }
  }

  &.fullWidth {
    :global(.ant-tabs-nav-list) {
      width: 100%;
    }

    :global(.ant-tabs-tab) {
      flex: 1 1 0;
    }
  }

  &.dark {
    :global(.ant-tabs-nav) {
      padding: var(--spacing-2) var(--spacing-4) 0 var(--spacing-2);
      margin-bottom: var(--spacing-3);
      &:before {
        border-bottom: 1px solid var(--grayscale-smoke-darken-60);
      }
    }
    :global(.ant-tabs-tab) {
      &:not(:global(.ant-tabs-tab-disabled)):hover :global(.ant-tabs-tab-btn) {
        background-color: var(--special-hover-dark);
        color: var(--theme-primary-light);
      }

      :global(.ant-tabs-tab-btn) {
        color: var(--text-dark-medium-emphasis);
      }

      &:global(.ant-tabs-tab-disabled) :global(.ant-tabs-tab-btn) {
        color: var(--text-dark-low-emphasis);
      }
    }

    :global(.ant-tabs-tab-active) :global(.ant-tabs-tab-btn) {
      color: var(--theme-primary-light);
    }

    &.tabsSecondary {
      :global(.ant-tabs-tab) {
        &:not(:global(.ant-tabs-tab-disabled)):hover :global(.ant-tabs-tab-btn) {
          color: var(--text-dark-high-emphasis);
        }

        :global(.ant-tabs-tab-btn) {
          color: var(--text-dark-high-emphasis);
        }
      }

      :global(.ant-tabs-tab-active) :global(.ant-tabs-tab-btn) {
        color: var(--text-dark-high-emphasis);
      }
    }
  }
}
