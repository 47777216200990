@use 'designSystem2022.module' as ds;

.wrapper {
  text-align: center;
  white-space: nowrap;
  color: var(--text-light-high-emphasis);

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
  }

  :global {
    .ant-modal {
      display: inline-block;
      vertical-align: middle;
      top: 0;
      text-align: left;
      max-width: calc(100% - 16px);
      padding-bottom: 0;
    }

    .ant-modal-content {
      border-radius: 8px;
      overflow: hidden;
      background-color: white;
      padding: 0 !important;

      .ant-modal-header {
        margin: var(--spacing-6) var(--spacing-6) 0 var(--spacing-6);
        padding: 0 0 var(--spacing-3);
        background-color: transparent;
        border-bottom-color: transparent;

        .ant-modal-title {
          height: 28px;
          @include ds.HeadingsH5;
          color: var(--text-light-high-emphasis);
        }
      }

      .ant-modal-close {
        color: var(--text-light-high-emphasis);
        font-weight: normal;
        top: 20px;
        right: var(--spacing-6);
        :hover {
          color: var(--text-light-medium-emphasis);
        }

        .ant-modal-close-x {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .ant-modal-body {
        padding: var(--spacing-4) var(--spacing-6) var(--spacing-6);
      }
    }
  }
}

.buttonsContainer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
  padding: var(--spacing-3) 0 0;
  margin-top: var(--spacing-4);
  &.showBorder {
    border-top: 1px solid var(--grayscale-smoke-lighten-90);
  }
}

.showHeaderBorder {
  :global(.ant-modal-header) {
    border-color: var(--grayscale-smoke-lighten-90) !important;
  }

  &.dark {
    :global(.ant-modal-header) {
      border-color: var(--grayscale-smoke-darken-60) !important;
    }
  }
}

.dark {
  color-scheme: dark;

  .buttonsContainer.showBorder {
    border-top: 1px solid var(--grayscale-smoke-darken-60);
  }

  :global {
    .ant-modal-content {
      background-color: var(--surface-alternate);
      color: white !important;

      .ant-modal-header {
        .ant-modal-title {
          color: var(--text-dark-high-emphasis);
        }
      }

      .ant-modal-close {
        color: var(--text-dark-high-emphasis);
        :hover {
          color: var(--text-dark-medium-emphasis);
        }
      }
    }
  }
}

.closeIcon {
  font-weight: normal !important;
}

.noMaxWidth {
  :global {
    .ant-modal {
      max-width: none;
    }
  }
}
