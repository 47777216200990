@use 'designSystem2022.module' as ds;

.step {
  max-width: 750px;

  img {
    margin-top: 32px;
  }

  p {
    margin: 0;
  }

  .block {
    background-color: var(--surface-alternate-1);
    box-shadow:
      0px 0px 18px 16px rgba(26, 29, 31, 0.7),
      inset 0px 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    border: 1px solid;
    border-image-source: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0) 100%
    );

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 24px;
    padding: 16px;

    text-align: start;
    @include ds.BodyBody-1Paragraph;
  }

  .innerStepBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 8px;

    .innerStep {
      @include ds.SubtitlesSubtitle-1;
      color: var(--text-dark-high-emphasis);

      .tag {
        display: inline-flex;
        margin-right: 4px;
      }
    }
  }
}
