.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  width: 525px;
  color: var(--text-light-medium-emphasis);
  background-color: var(--surface-standard);
  text-align: left;
  overflow-x: hidden;
  position: relative;
  height: 100%;

  .headerContainer {
    margin: var(--spacing-6) var(--spacing-6) 0 var(--spacing-6);
    flex: 0 0 auto;
    border-bottom: 1px solid var(--grayscale-smoke-lighten-90);
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: var(--spacing-6);
    justify-content: flex-start;
  }

  .buttonsContainer {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    margin: 0 var(--spacing-6) var(--spacing-6) var(--spacing-6);
    padding-top: var(--spacing-4);
    border-top: 1px solid var(--grayscale-smoke-lighten-90);
  }
}
