.onboardingView {
  & > main {
    padding: var(--spacing-4) 0;

    > *:first-child {
      width: var(--onboarding-content-width, 540px);
      max-width: min(calc(100% - 2 * var(--spacing-4)), 1100px);
    }
  }
}
