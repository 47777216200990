@use 'designSystem2022.module' as ds;

.container {
  gap: 24px;

  .description {
    @include ds.BodyBody-2Paragraph;
    color: var(--text-light-medium-emphasis);
    white-space: normal;
  }
}
