@use 'designSystem2022.module' as ds;

.demoWorkspaceBannerWrapper {
  background-color: var(--theme-warning-default);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--spacing-1);
  z-index: 10;

  .demoWorkspaceBanner {
    @include ds.BodyBody-1Medium;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    background-color: var(--theme-warning-default);
    color: var(--text-dark-high-emphasis);
    gap: var(--spacing-2);
    border-radius: var(--spacing-1);
    padding: 0 var(--spacing-1) 0 var(--spacing-3);
  }
}
