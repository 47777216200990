@use 'app/device/deviceInteractor/deviceInteractor.module' as deviceInteractor;

.image {
  width: 400px;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.devicePanel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 #{deviceInteractor.$deviceInteractorButtonSize + px};
}
