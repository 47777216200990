@use 'designSystem2022.module' as ds;

.card {
  box-shadow: var(--shadows-default);
  border-radius: 6px;
  padding: var(--spacing-3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: var(--surface-standard-1);
  margin-bottom: 10px;

  .timeLimitText {
    @include ds.BodyBody-2Regular;
    display: flex;
    align-items: center;

    .clock {
      margin-right: var(--spacing-4);
    }
  }

  .buttonWrapperDisabled {
    cursor: not-allowed;

    :global(.ant-btn):disabled {
      pointer-events: none;
    }
  }
}
