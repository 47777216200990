.stepModal {
  :global {
    .ant-modal-content {
      border-radius: 4px !important;
      .ant-modal-close {
        top: 14px !important;
      }
    }
  }
}
