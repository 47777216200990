@use 'designSystem2022.module' as ds;

:global(.ant-collapse).collapse {
  border-radius: 4px;
  border: none;
  box-shadow: var(--shadows-card);
  background-color: var(--surface-standard-1);
  min-width: 600px;

  :global {
    .ant-collapse-content {
      border-top-style: hidden;
    }
    .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px 24px 24px 24px;
    }
    .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
      padding: 26px 24px 26px 24px;
      user-select: none;
    }
    .ant-collapse-item {
      border-bottom-style: hidden;
    }
  }

  .taskButtons {
    display: flex;
  }

  .taskHeaderExtraLabel {
    @include ds.CaptionDefault;
    color: var(--text-light-low-emphasis);
  }

  .taskHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .taskDescription {
    @include ds.BodyBody-1Paragraph;
    color: var(--text-light-medium-emphasis);
    margin-bottom: 24px;
  }

  .taskHeaderLeftSide {
    display: flex;
    align-items: center;

    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
    }
  }

  .divider {
    margin-bottom: 24px;
    margin-top: -12px;
    border-color: var(--grayscale-smoke-lighten-90);
  }

  .icon {
    &.checked {
      color: var(--theme-success-default);
    }
    &.unchecked {
      color: var(--theme-primary-default);
    }
    margin-right: 10px;
  }

  .title {
    @include ds.BodyBody-1Medium;
    &.checked {
      text-decoration-line: line-through;
      color: var(--text-light-medium-emphasis);
    }
    &.unchecked {
      color: var(--text-light-high-emphasis);
    }
  }
}
