.bottomDrawerWrapper {
  :global {
    .ant-drawer-content {
      background-color: var(--surface-alternate-2);
    }

    .ant-drawer-content-wrapper {
      border-radius: var(--spacing-1) var(--spacing-1) 0 0;
      overflow: hidden;
    }
  }

  .elementsDrawer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: var(--spacing-2) var(--spacing-4);
    color: var(--text-dark-medium-emphasis);

    .cta {
      margin-left: auto;
    }
  }
}
