@use 'designSystem2022.module' as ds;

.container {
  width: 100%;
  height: 100%;
  overflow: auto;

  .toolbar {
    display: flex;
    padding: 10px 20px;
    gap: 20px;
    border-bottom: 1px solid var(--grayscale-smoke-darken-60);

    .admin {
      flex: 0 0 auto;
      width: 30px;
      height: 30px;
      min-height: 30px;
      min-width: 30px;
    }
  }

  .predicateEvaluator {
    flex: 1 0 auto;
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--surface-alternate);
    color: var(--text-dark-medium-emphasis);

    font-size: 12px;
    justify-content: left;
    align-items: baseline;
    flex-direction: row;

    .predicateSelect {
      width: 150px;
      margin: 0 10px;
    }
    .predicateValue {
      height: 30px;
      width: 200px;
    }
    .predicateSearch {
      display: inline-block !important;
      height: 30px;
      margin-left: 10px;
    }

    .resultLine {
      margin-left: auto;
    }

    .reset {
      border: none;
      display: inline !important;
      margin-left: 5px;
    }
  }

  ol {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-2);
    margin: 0px;

    > li {
      list-style-type: none;
    }
  }

  .windowLine {
    @include ds.BodyBody-2Medium;
    color: var(--text-dark-high-emphasis);
    margin: var(--spacing-2) 0px;
  }

  .nodeLineContainer {
    padding: 1px 0;

    &:focus-visible {
      outline: none;
    }
    scroll-margin: var(--spacing-4);
  }

  .nodeLine {
    display: block;
    cursor: pointer;
    @include ds.BodyBody-2Medium;
    padding: var(--spacing-1) var(--spacing-2);
    border-radius: var(--spacing-1);
    border-bottom: solid 1px transparent;

    &.nodeLineExcluded {
      cursor: default;
      font-size: 11px;
    }
    &.nodeLineExcluded:hover {
      font-weight: 500;
    }

    &:hover {
      font-weight: 600;
    }
  }
}
