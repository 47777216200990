@use 'designSystem2022.module' as ds;

.h1 {
  @include ds.HeadingsH1;
}

.h2 {
  @include ds.HeadingsH2;
}

.h3 {
  @include ds.HeadingsH3;
}

.h4 {
  @include ds.HeadingsH4;
}

.h5 {
  @include ds.HeadingsH5;
}

.h6 {
  @include ds.HeadingsH6;
}

.light {
  color: var(--text-light-high-emphasis);
}

.dark {
  color: var(--text-dark-high-emphasis);
}
