@use 'designSystem2022.module' as ds;

.containers {
  display: flex;
  flex-direction: row;
}

.container {
  @extend .containers;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: var(--spacing-4);
  margin-top: var(--spacing-4);
}

.sessionButtonContainer {
  @extend .containers;
  align-items: center;
  gap: var(--spacing-4);
}

.title {
  font-size: 13px;
  font-weight: 600;
}
.appTitle {
  @extend .title;
  color: var(--theme-success-default);
}
.sessionTitle {
  @extend .title;
  color: var(--secondary-theme-pink-default);
}

.name {
  color: var(--text-dark-high-emphasis);
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 8px;
}

.appName {
  @extend .name;
  margin-top: 8px;
}

.sessionNameContainer {
  @extend .containers;
  gap: var(--spacing-1);

  .sessionName {
    @extend .name;
  }
}

.authorContainer {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;

  .author {
    @include ds.BodyBody-1Paragraph;
    color: var(--text-dark-low-emphasis);
  }
}

.deviceContainer {
  display: flex;
  gap: 8px;
  @include ds.BodyBody-1Paragraph;
  color: var(--text-dark-low-emphasis);
  margin-bottom: 16px;

  .modelAndOs {
    color: var(--text-dark-high-emphasis);
  }
}

.sessionStartContainer {
  color: var(--text-dark-low-emphasis);
  margin-bottom: 16px;
  @include ds.BodyBody-1Paragraph;

  strong {
    color: var(--text-dark-high-emphasis);
  }
}

.useForScript {
  width: 130px;
}

.download {
  width: 160px;
}

.relaunch {
  width: 120px;
}

:global(.ant-card).card {
  background-color: var(--surface-alternate-1);
  border-radius: 12px;
  box-shadow: var(--shadows-default);

  :global(.ant-card-body) {
    padding-top: 8px;
  }

  :global(.ant-card-head-title) {
    @include ds.HeadingsH6;
    color: var(--text-light-high-emphasis);
  }

  :global(.ant-card-head-title),
  :global(.ant-card-head) {
    border-bottom: none;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.appCard {
  @extend .card;
  flex-grow: 0;
}

.detailsCard {
  @extend .card;
  flex-grow: 1;
}
