@use 'designSystem2022.module' as ds;
table {
  --table-border: 1px solid var(--grayscale-smoke-lighten-90);
  :global {
    .ant-table-thead {
      .ant-table-cell {
        @include ds.BodyBody-2Medium;
        color: var(--text-light-medium-emphasis);
        background-color: var(--surface-standard-0);
        border-top: var(--table-border);

        &:before {
          visibility: hidden;
        }

        &:first-child {
          border-radius: 6px 0 0 0;
          border-left: var(--table-border);
          overflow: hidden;
        }

        &:last-child {
          border-radius: 0 6px 0 0;
          border-right: var(--table-border);
          // Cannot use overflow: hidden here otherwise it will break the shadow
          // on hidden scrolled columns when the table is scrollable horizontally
          // overflow: hidden;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          @include ds.BodyBody-1Regular;
          color: var(--text-light-high-emphasis);
          &:first-child {
            border-left: var(--table-border);
          }
          &:last-child {
            border-right: var(--table-border);
          }
        }
      }
    }
  }
}

// The buttonWrapper is always displayed to allow for a margin
// even when the action button is not.
.buttonWrapper {
  padding: 0 var(--spacing-4) var(--spacing-4) var(--spacing-4);
  width: 100%;
  display: flex;
  justify-content: center;

  > *:first-child {
    margin-top: var(--spacing-4);
  }
}

.skeletonRow {
  td {
    background-color: white;
    overflow: hidden;
  }
}
