@use 'designSystem2022.module' as ds;

.listWrapper {
  .table {
    :global(th.ant-table-cell) {
      border-bottom-style: none;
    }

    .menuButton {
      border: none;
    }
  }
  .switchWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .addButtonWrapper {
    padding: var(--spacing-3) var(--spacing-3);
    width: 100%;
    border-width: 1px;
    border-color: var(--grayscale-smoke-lighten-90);
    border-style: none solid solid;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: var(--spacing-4);

    &:first-child {
      border-top-style: solid;
    }
  }
}
