.container {
  width: 100%;
  height: 100%;

  padding: 1em;
  background-color: var(--surface-alternate);
  color: var(--text-dark-medium-emphasis);

  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  gap: 24px;
  color-scheme: dark;

  .stepScrollWrapper {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    text-align: center;
  }

  .step {
    // It would be a lot nicer if we could use the 'safe' alignment mode on the parent and not have this
    // additional node but it's not yet implemented by safari and quite new on Firefox as of 2024.
    // https://caniuse.com/mdn-css_properties_align-self_flex_context_safe_unsafe
    //
    // align-items: safe center;
    // justify-content: safe center;
    margin: auto;
  }

  .buttons {
    align-self: center;
  }

  .progress {
    align-self: center;
  }
}
