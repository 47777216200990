@use 'designSystem2022.module' as ds;

.compatibilityModal {
  :global(.ant-modal-body) {
    padding: 0 !important;
  }

  .modalContainer {
    display: flex;
    width: 100%;
    height: 100%;

    .controlsContainer {
      display: flex;
      flex-direction: column;
      background-color: white;
      width: 540px;
      padding: var(--spacing-6);

      h6 {
        margin-bottom: 0;
      }

      .sectionTitle {
        @include ds.SubtitlesSubtitle-1;
        color: var(--text-light-high-emphasis);
        margin-top: var(--spacing-4);
        margin-bottom: var(--spacing-3);
      }

      .similarityCard {
        @include ds.BodyBody-2Regular;
        display: flex;
        align-items: center;
        padding: var(--spacing-4);
        border-radius: 4px;
        box-shadow: var(--shadows-card);
        gap: var(--spacing-4);

        .success {
          color: var(--theme-success-default);
        }

        .warning {
          color: var(--theme-warning-default);
        }
      }

      .buttonsContainer {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        gap: var(--spacing-4);
        padding-top: var(--spacing-4);
        margin-top: auto;
      }
    }
  }
}
