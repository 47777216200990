.container {
  position: relative;
  display: block;
  white-space: pre;
  margin: 16px 0;
  border: 1px solid var(--grayscale-smoke-lighten-20);
  border-radius: var(--spacing-1);
  box-shadow: var(--shadows-card);
  background-color: var(--grayscale-smoke-lighten-90);
  font-size: var(--font-size-medium);
  font-weight: 400;

  .code {
    padding: 16px 60px 16px 16px;
    overflow: auto;
  }

  .clipboard {
    position: absolute;
    top: var(--spacing-1);
    right: var(--spacing-1);
  }
}
