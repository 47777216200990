@use 'designSystem2022.module' as ds;
@use 'mixins';

$appSwitcherItemHeight: 48px;

.appSwitcherWrapper {
  padding: 0 var(--spacing-2);
  box-shadow: 0 1px 0 0 var(--grayscale-smoke-lighten-90);
  transition: 0.3s ease;

  &.dark {
    box-shadow: 0 1px 0 0 var(--grayscale-smoke-darken-60);

    :global {
      .ant-select-selector {
        &:hover {
          background-color: var(--special-hover-dark) !important;
        }
      }
      .ant-select-dropdown {
        background-color: var(--surface-alternate-1);
      }
    }
  }

  .appSwitcherItem {
    &:hover {
      background-color: transparent !important;
    }
  }

  .appSwitcherContainer {
    width: 100%;
    height: 72px;
    display: flex !important;
    align-items: center;

    .suffixIcon {
      margin-right: var(--spacing-4);
      color: var(--text-light-medium-emphasis);

      &.dark {
        color: var(--text-dark-medium-emphasis);
      }
    }
  }

  :global {
    .ant-select-selector {
      height: 58px !important;
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
      border-radius: var(--spacing-2);
      transition: 0.3s ease;
      background-color: transparent !important;

      &:hover {
        background-color: var(--special-hover-light) !important;
      }
    }

    .ant-select-dropdown {
      padding: 0 !important;
    }

    .ant-select-open {
      .ant-select-selector {
        background-color: var(--special-hover-light) !important;
      }
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0 !important;
    }

    .ant-select-arrow {
      right: 0 !important;
    }
  }
}

.appSwitcherItem {
  width: 100%;
  height: $appSwitcherItemHeight;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: var(--spacing-2);
  padding: var(--spacing-2) !important;
  transition: 0.3s ease;

  &:hover {
    background-color: var(--special-hover-light);
  }

  &.dark {
    &:hover {
      background-color: var(--special-hover-dark);
    }
  }

  .checkIcon {
    display: none;
  }

  .appInfo {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
    @include ds.BodyBody-1Medium;

    .appName {
      @include mixins.ellipsis(120px);
      color: var(--text-light-high-emphasis);

      &.dark {
        color: var(--text-dark-high-emphasis);
      }
    }

    .appType {
      @include ds.SmallLabel;
      color: var(--text-light-low-emphasis);

      &.dark {
        color: var(--text-dark-low-emphasis);
      }
    }
  }
}

.dark {
  &:global(.ant-select-dropdown) {
    background-color: var(--surface-alternate-1) !important;
  }
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
  padding: 0;

  .buttonWrapper {
    border-top: 1px solid var(--grayscale-smoke-lighten-90);
    margin: 0 var(--spacing-2);
    padding: var(--spacing-2) 0;
    transition: 0.3s ease;

    &.dark {
      border-top: 1px solid var(--grayscale-smoke-darken-60);

      :global {
        .ant-select-item-option-content {
          &:hover {
            background-color: var(--special-hover-dark);
          }
        }
      }
    }
  }

  .title {
    @include ds.CaptionDefault;
    color: var(--text-light-low-emphasis);
    padding: var(--spacing-2) var(--spacing-3) var(--spacing-1) var(--spacing-4);
    margin-bottom: var(--spacing-1);
    transition: 0.3s ease;

    &.dark {
      color: var(--text-dark-low-emphasis);
    }
  }

  .checkIcon {
    color: var(--theme-success-default);
    margin-left: auto;
    display: block;
  }

  .appName {
    @include ds.BodyBody-2Regular;
  }

  :global {
    .rc-virtual-list-holder-inner {
      padding: 0 var(--spacing-2);
    }

    .ant-select-item-option {
      height: calc($appSwitcherItemHeight + var(--spacing-1));
      padding: 2px 0 !important;
      transition: 0.3s ease;

      &:hover {
        background-color: transparent;
      }
    }

    .ant-select-item-option-state {
      align-self: center;
    }

    .ant-select-item-option-content {
      border-radius: var(--spacing-1);
      transition: 0.3s ease;
      &:hover {
        background-color: var(--special-hover-light);
      }
    }

    .ant-select-item-option-active {
      background-color: transparent !important;
    }

    .ant-select-item-option-selected {
      background-color: transparent !important;
      .ant-select-item-option-content {
        background-color: var(--special-hover-light);
      }
    }
  }
}
