.sidebarMiniContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: var(--sidebar-mini-width);
  background-color: white;
  border-right: 1px solid var(--grayscale-smoke-lighten-90);
  transition: 0.3s ease;

  &.dark {
    background-color: var(--surface-alternate-1);
    border-right: 1px solid var(--grayscale-smoke-darken-60);
  }

  .sidebarMiniHeader {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 0 0 var(--grayscale-smoke-lighten-90);
    cursor: pointer;
    transition: 0.3s ease;

    &.dark {
      box-shadow: 0 1px 0 0 var(--grayscale-smoke-darken-60);
    }
  }

  .sidebarMiniBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .sidebarSection {
      padding-top: 46px;
      padding-bottom: var(--spacing-4);
      padding-left: var(--spacing-2);
      padding-right: var(--spacing-2);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2);
      border-bottom: 1px solid var(--grayscale-smoke-lighten-90);
      transition: 0.3s ease;

      &.dark {
        border-bottom: 1px solid var(--grayscale-smoke-darken-60);
      }

      &:last-child {
        border-bottom: none !important;
      }
    }
  }

  .sidebarMiniBodyBottom {
    display: flex;
    align-items: flex-end;

    .sidebarBottomSection {
      padding: var(--spacing-2);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2);
      margin-top: auto;
    }
  }

  .sidebarMiniFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-2);
    border-top: 1px solid var(--grayscale-smoke-lighten-90);
    transition: 0.3s ease;

    &.dark {
      border-top: 1px solid var(--grayscale-smoke-darken-60);
    }
  }
}
