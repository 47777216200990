.container {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  padding: 4px;

  .dot {
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    flex: 0 0 auto;

    .border {
      height: 100%;
      width: 100%;
      border-radius: 100px;
      padding: 1px;

      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;

      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.12) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    background: #33383f;
    transition:
      background-color 0.3s,
      width 0.6s;

    &.current {
      width: 16px;
      background: #5a5de2;
      .border {
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }

    &.completed {
      background: #38c793;
      .border {
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }
}
