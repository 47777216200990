@use 'designSystem2022.module' as ds;

.sectionWrapper {
  min-width: 540px;
  max-width: 700px;
  padding: var(--spacing-6);
  margin: 0 auto;
  @media (min-width: 1200px) {
    padding-top: var(--spacing-12);
  }

  .buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .actionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .detailsWrapper {
    margin-top: var(--spacing-4);
  }

  .details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding: var(--spacing-2);
    &:hover {
      border-radius: 4px;
      background-color: var(--grayscale-smoke-lighten-90);
    }
  }

  .detailsText {
    @include ds.BodyBody-2Medium;
    color: var(--text-light-high-emphasis);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }

  .progressBar {
    margin-top: var(--spacing-2);
  }

  .hiddenProgressBar {
    @extend .progressBar;
    opacity: 0;
  }
}
