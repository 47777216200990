@use 'designSystem2022.module' as ds;

$headerHeight: 72px;

:export {
  headerHeight: $headerHeight;
}

.header {
  @include ds.SubtitlesSubtitle-1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $headerHeight !important;
  width: 100%;
  z-index: 200;
  box-shadow: 0px 1px 0px var(--grayscale-smoke-lighten-90);
  padding: 24px 32px !important;
  line-height: normal !important;
  color: var(--text-light-high-emphasis) !important;

  &.dark {
    background-color: var(--surface-alternate-1);
    color: var(--text-dark-high-emphasis) !important;
    box-shadow: 0px 1px 0px var(--grayscale-smoke-darken-60);
  }
}
