@use 'designSystem2022.module' as ds;

.layout {
  height: 100%;

  .header {
    @include ds.BodyBody-1Semi-Bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 24px 32px;
    color: var(--text-light-high-emphasis);
    border-bottom: 1px solid var(--grayscale-smoke-lighten-90);

    .headerButtons {
      display: flex;
      align-items: center;
      gap: 16;
    }
  }

  .content {
    padding-top: 48px;
    padding-bottom: 48px;
    height: calc(100% - 70px);
    overflow-y: auto;

    .contentRow {
      margin: auto !important;
      max-width: 1200px;
    }
  }
}
