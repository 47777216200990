.deviceCascader {
  :global {
    .ant-select-selector {
      margin: 0;
      padding: 0 !important;
    }

    .ant-select-selection-item {
      padding: var(--spacing-2) !important;
    }

    .ant-select-selection-overflow {
      gap: var(--spacing-2);
      padding: var(--spacing-2);
    }

    .ant-select-selection-overflow-item-rest {
      .ant-select-selection-item {
        height: 26px;
        border: none;
        border-radius: 2px;
      }
    }
    .ant-select-selection-search {
      padding: 0px !important;
      .ant-select-selection-search-input {
        height: 26px;
        line-height: 26px;
      }
    }

    .ant-cascader-menu-item[title] {
      max-width: calc(var(--column-width) - 2 * var(--spacing-2));
    }

    .ant-cascader-dropdown {
      max-width: 100%;
    }
  }
}

.deviceCascaderPopup {
  :global {
    .ant-cascader-menu-item-content {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .ant-cascader-checkbox {
      display: none;
    }
    .ant-cascader-menu {
      padding: var(--spacing-2);
      width: var(--column-width);

      .ant-cascader-menu-item {
        border-radius: 6px;
        .ant-cascader-checkbox-checked ~ .ant-cascader-menu-item-content {
          display: flex;
          flex-direction: row;
          align-items: center;

          &::after {
            content: '\f272';
            margin-left: auto;
            font-size: 12px;
            font-family: bootstrap-icons !important;
            color: var(--theme-success-default);
          }
        }
      }
      .ant-cascader-menu-item-active {
        background-color: rgba(0, 0, 0, 0.05);
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}
