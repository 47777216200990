@use 'designSystem2022.module' as ds;

.accountLocked {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  max-width: 404px;
  text-align: center;
  white-space: pre-wrap;

  picture {
    margin-bottom: 32px;
  }

  h6 {
    margin-bottom: 16px;
  }

  p {
    @include ds.BodyBody-1Paragraph;
    color: var(--text-light-medium-emphasis);
    margin-bottom: 32px;
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
}
