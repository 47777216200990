@use 'designSystem2022.module' as ds;

.container {
  border-radius: 4px;
  background-color: var(--surface-standard-1);
  position: relative;
  padding: var(--spacing-6);
  box-shadow: var(--shadows-card);
  margin-bottom: var(--spacing-8);
  max-width: 800px;
}

.h5 {
  @include ds.HeadingsH5;
  color: var(--text-light-high-emphasis);
}

.divider {
  margin: var(--spacing-4) 0px;
}

.description {
  @include ds.BodyBody-2Paragraph;
  color: var(--text-light-medium-emphasis);
  margin-bottom: var(--spacing-2);
}

.form {
  margin-top: var(--spacing-6) !important;
  margin-bottom: 0px;

  .radioOption {
    .radioOptionTitle {
      @include ds.SubtitlesSubtitle-2;
      color: var(--text-light-high-emphasis);
      margin-bottom: var(--spacing-2);
    }
    .radioOptionDescription {
      @include ds.BodyBody-2Paragraph;
      color: var(--text-light-medium-emphasis);
      margin-bottom: var(--spacing-2);
    }
    .domainFormItem {
      width: 50%;
      margin-bottom: 0px;
    }
  }

  .formButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0px;
  }
}
