.studioContent {
  background-color: var(--surface-alternate);
  color-scheme: dark;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
  isolation: isolate;
}

.studioMainContent {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  min-height: 0;
  position: relative;
  background-color: var(--surface-alternate);
}

.studioVideoAndFlow {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  min-height: 0;
  position: relative;
  align-items: stretch;
  overflow: hidden;
}
