@use 'designSystem2022.module' as ds;

.dependencyPanel {
  background: var(--surface-alternate);
  border-right: 1px solid var(--grayscale-smoke-darken-40);
  position: absolute;
  overflow: auto;
  padding: var(--spacing-6);
  height: 100%;
  width: var(--dependency-panel-width);

  .dependencyPanelHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;

    .dependencyTitle {
      color: var(--text-dark-high-emphasis);
      font-size: 12px;
      margin-bottom: 0px;
      text-transform: uppercase;
    }
  }
}
