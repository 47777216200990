@use 'designSystem2022.module' as ds;

.currentInteractionContainer {
  padding: var(--spacing-4);
  background-color: var(--surface-standard-1);
  box-shadow: var(--shadows-default);
  border-radius: 4px;

  .title {
    @include ds.BodyBody-2Medium;
    color: var(--text-light-medium-emphasis);
    border-bottom: 1px solid var(--grayscale-smoke-lighten-90);
    padding-bottom: var(--spacing-4);
    margin-bottom: var(--spacing-4);
  }

  .content {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
  }
}
