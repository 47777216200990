.bottomDrawerWrapper {
  :global {
    .ant-drawer-content {
      background-color: var(--surface-alternate-2);
    }

    .ant-drawer-content-wrapper {
      border-radius: var(--spacing-1) var(--spacing-1) 0 0;
      overflow: hidden;
    }
  }

  .bottomDrawer {
    padding: var(--spacing-2);
    display: flex;
    gap: var(--spacing-4);
    justify-content: space-between;
    align-items: center;
    background-color: var(--surface-alternate-2);
  }
}
