.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container > :last-child {
  padding: var(--spacing-4);
  border-radius: 0;
  max-height: 200px;
}
