@use 'designSystem2022.module' as ds;

.sidebarItem {
  @include ds.BodyBody-1Medium;
  height: var(--spacing-8);
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
  color: var(--text-light-medium-emphasis);
  border-radius: 6px;
  padding: var(--spacing-2);
  transition: 0.3s ease;

  &.dark {
    color: var(--text-dark-medium-emphasis);
  }

  &:hover {
    background-color: var(--special-hover-primary);
    color: var(--theme-primary-default);

    &.dark {
      background-color: var(--special-hover-primary-dark);
      color: var(--theme-primary-light);
    }
  }

  .sectionLabel {
    flex: 1;
  }

  .premiumLabel {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background-color: var(--theme-primary-soft);
    i {
      color: var(--theme-primary-dark);
    }

    &.dark {
      background-color: var(--special-hover-primary-dark);
      i {
        color: var(--theme-primary-light);
      }
    }
  }
}

.selectedSidebarItem {
  color: var(--theme-primary-default);
  background-color: var(--special-hover-primary);
  transition: 0.3s ease;

  &.dark {
    color: var(--theme-primary-light);
    background-color: var(--special-hover-primary-dark);
  }
}

.sidebarIcon {
  width: var(--spacing-4);
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.disabled {
  cursor: not-allowed;
  color: var(--text-light-low-emphasis);
  &:hover {
    color: var(--text-light-low-emphasis);
  }
}
