.container {
  overflow: auto hidden;

  .filters {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-6);
    height: 36px;
    align-items: center;
  }

  .input {
    margin-left: auto;
    min-width: 150px;
    max-width: 260px;

    &:global(.ant-input-affix-wrapper) {
      // Restore the original padding instead of using inputWrapped one
      padding: 5.2px 12px;
      height: 100%;
    }
  }

  .inputIcon {
    color: var(--text-light-medium-emphasis);
  }
  .inputIconDark {
    color: var(--text-dark-medium-emphasis);
  }

  .tagFiltersContainer {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-2) var(--spacing-4);
    max-height: 60px;
    overflow-y: auto;
    margin-top: var(--spacing-4);
  }
}
