@use 'designSystem2022.module' as ds;
@use 'mixins';

.bottomMenu {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
  padding: var(--spacing-2);
  border-top: 1px solid var(--grayscale-smoke-lighten-90);
  transition: 0.3s ease;

  &.dark {
    border-top: 1px solid var(--grayscale-smoke-darken-60);

    & i {
      color: var(--text-dark-medium-emphasis);
    }
  }

  .menuButton {
    height: 48px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);
  }

  & i {
    color: var(--text-light-medium-emphasis);
  }

  .bottomMenuContent {
    display: flex;
    align-items: center;
    gap: var(--spacing-3);

    .userName {
      @include ds.BodyBody-1Medium;
      @include mixins.ellipsis(105px);
      color: var(--text-light-medium-emphasis);

      &.dark {
        color: var(--text-dark-medium-emphasis);
      }
    }
  }
}

.overlayContainer {
  display: flex;
  flex-direction: column;
  width: calc(var(--sidebar-width) - 4 * var(--spacing-2));
  gap: var(--spacing-1);

  .overlayButton {
    color: var(--text-light-medium-emphasis) !important;
    height: 32px;
    justify-content: left;
    width: 100%;
    transition: 0.3s ease;

    &.dark {
      color: var(--text-dark-medium-emphasis) !important;
    }
  }

  .adminMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: var(--spacing-2);
  }

  .divider {
    margin-top: var(--spacing-1);
    margin-bottom: var(--spacing-1);
  }

  .logoutButton {
    height: 32px;
    justify-content: left;
  }
}
