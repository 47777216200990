@use 'designSystem2022.module' as ds;

@use './deviceInteractor.module.scss' as deviceInteractor;

.rotateIcon g {
  fill: var(--text-dark-high-emphasis);
}
.deviceActionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: #{deviceInteractor.$deviceInteractorMargin + px};
  margin-bottom: #{deviceInteractor.$deviceInteractorMargin + px};
  gap: 12px;
}
.buttonPlaceholder {
  width: #{deviceInteractor.$deviceInteractorButtonWidth + px};
}
.tooltip {
  :global(.ant-tooltip-inner) {
    background-color: white;
    color: var(--text-light-high-emphasis);
    font-weight: 500;
  }
}
.disabledPointerEvents {
  pointer-events: none;
}
.disabledCursor {
  cursor: not-allowed;
}
.selected {
  color: var(--theme-primary-light) !important;
  background-color: var(--special-opacity-primary) !important;
}
