@use 'designSystem2022.module' as ds;

.checkbox {
  width: 20px;
  height: 20px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: var(--theme-success-default);
  margin-right: var(--spacing-1);
}

.textButton {
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--theme-primary-default);
  margin-left: var(--spacing-1);
  font-size: 14px;
  font-weight: 500;
  &:hover {
    background-color: transparent;
    color: var(--theme-primary-default);
  }

  &:focus {
    background-color: transparent;
    color: var(--theme-primary-default);
  }
}

.requirementItem {
  @include ds.BodyBody-1Regular;
  color: var(--text-light-high-emphasis);
  display: flex;
  align-items: center;
  padding-bottom: 1px;
  padding-left: var(--spacing-1);
  white-space: break-spaces;
  margin-bottom: var(--spacing-2);
  gap: var(--spacing-1);

  .requirementItemText {
    display: flex;
    align-items: center;
  }

  .italic {
    @include ds.BodyBody-2Italic;
    color: var(--text-light-medium-emphasis);
  }
}

.requirements {
  @include ds.SubtitlesSubtitle-1;
  color: var(--text-light-medium-emphasis);
  margin-bottom: var(--spacing-6);
}
