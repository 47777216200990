@use 'designSystem2022.module' as ds;

.bottomLine {
  height: 1px;
  width: 100%;
  margin-top: 1px;
  flex: none;
}

.yellowLine {
  background-color: var(--theme-attention-default);
}

.timelineWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  .timelinListWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .timelineListCommentSection {
    width: 100%;
    background-color: var(--surface-alternate-1);
    border-top: solid 2px var(--grayscale-smoke-darken-60);
    flex-shrink: 0;
    display: flex;
    padding: var(--spacing-4) var(--spacing-4) var(--spacing-6) var(--spacing-4);
    align-items: flex-end;
    gap: var(--spacing-3);

    .timelineListCommentSectionInput {
      flex-grow: 1;
      align-self: stretch;
      background-color: var(--surface-alternate);
      resize: none;
    }
  }
}

.attributes {
  min-height: 100%;
  background-color: var(--surface-alternate);
}
