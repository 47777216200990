@use 'designSystem2022.module' as ds;

.subtitle {
  @include ds.BodyBody-2Paragraph;
  color: var(--text-light-medium-emphasis);
}

.runTestModal {
  h2 {
    @include ds.HeadingsH5;
  }

  h3 {
    @include ds.SubtitlesSubtitle-1;
    margin-top: var(--spacing-3);
    margin-bottom: 0px;
  }

  h4 {
    @include ds.SubtitlesSubtitle-2;
  }

  :global(.ant-modal-body) {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
    align-items: stretch;
    overflow: hidden;
    max-height: calc(100vh - 16px);
    padding: 30px 0px;
    border-radius: 6px;
  }

  .spinnerContainer {
    min-height: 400px;
  }
}

.separator {
  height: 1px;
  width: 100%;
  background-color: var(--grayscale-smoke-lighten-90);
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-2);
}

.buttonsContainer {
  margin-left: auto;
  display: flex;
  gap: var(--spacing-4);
  padding: 0 30px;
}

.errorAlert {
  white-space: normal;
  margin-bottom: var(--spacing-2);
}

.ruleSelectOption {
  max-width: 100% !important;
}

.scrollableContainer {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  align-items: stretch;
  overflow-y: auto;
  padding: 0 30px;
}
