@use 'designSystem2022.module' as ds;

.modal:global(.ant-modal) {
  &.withPreview {
    width: calc(100vw - 50px) !important;
    max-width: 1300px;
  }

  :global(.ant-modal-body) {
    max-height: 740px;
    height: 85vh;
    display: flex;
    flex-direction: column;
  }
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1 1 0;
  height: 0px;

  .leftPanel,
  .rightPanel {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 auto;
    width: 0;

    .sectionTitle {
      @include ds.HeadingsH6;
      padding: var(--spacing-2) 0 var(--spacing-6) 0;
      color: var(--text-dark-medium-emphasis);
      overflow-x: hidden;
      text-overflow: ellipsis;
      flex: 0 0 auto;
    }

    :global {
      .ant-tabs {
        flex: 1 1 0px;
        height: 0px;
        .ant-tabs-nav {
          flex: 0 0 auto;
        }
        .ant-tabs-content-holder {
          flex: 1 1 0px;
          height: 0px;
          overflow-y: auto;
          .ant-tabs-content {
            height: 100%;
            .ant-tabs-tabpane {
              height: 100%;
            }
          }
        }
      }
      .cm-editor {
        height: 100%;
      }
    }
  }

  .divider {
    height: initial;
    margin: 0 var(--spacing-4);
    flex: 0 0 auto;
  }

  .leftPanel {
    .form {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-4);
      color-scheme: dark;
      color: var(--text-dark-high-emphasis);
      flex: 1 1 0;
      height: 0px;

      :global {
        .ant-radio-wrapper {
          @include ds.BodyBody-2Regular;
          color: var(--text-dark-high-emphasis);
        }
      }

      .codeEditor {
        height: 100%;
        width: 100%;
      }

      .methodUrlWrapper {
        width: 100%;
        display: flex;
        gap: var(--spacing-4);

        .method {
          width: 150px;
          flex: 0 0 auto;
        }

        .urlInputContainer {
          flex: 1 1 0;
          width: 0;
        }
      }

      .headersWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--spacing-2);
      }

      .buttonsContainer {
        display: flex;
        justify-content: flex-end;
        gap: var(--spacing-2);
      }
    }
  }
}
