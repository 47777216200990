@use 'designSystem2022.module' as ds;

.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  color-scheme: dark;
  color: var(--text-dark-high-emphasis);
  flex: 1 1 0;
  height: 0px;

  :global {
    .ant-radio-wrapper {
      @include ds.BodyBody-2Regular;
      color: var(--text-dark-high-emphasis);
    }
  }

  .codeEditor {
    height: 100%;
    width: 100%;
  }

  .methodUrlWrapper {
    width: 100%;
    display: flex;
    gap: var(--spacing-4);

    .method {
      width: 150px;
      flex: 0 0 auto;
      height: 38px;
    }

    .urlInputContainer {
      flex: 1 1 0;
      width: 0;
    }
  }

  .headersWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-2);
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-2);
  }

  .helperBanner {
    border: 1px solid var(--grayscale-smoke-default);
  }

  .headerItem {
    width: 100%;
    display: flex;
    align-items: center;
    gap: var(--spacing-2);

    .headerValueInputContainer,
    input {
      flex: 1 1 0;
      width: 0;
    }
  }
}
