.sessionAdmin {
  width: 100%;
  height: 100%;
  color: var(--text-dark-medium-emphasis);
  background-color: var(--surface-alternate);
  color-scheme: dark;
  padding: 24px;

  dl {
    display: grid;
    gap: 0 8px;
    grid-template-columns: max-content auto;
  }

  dt {
    grid-column-start: 1;
    text-align: right;
    color: var(--text-dark-low-emphasis);

    &::after {
      content: ':';
    }
  }

  dd {
    grid-column-start: 2;
    margin-bottom: unset;
  }

  h6 {
    color: var(--text-dark-high-emphasis);
  }
  :global {
    .ant-collapse {
      border-color: var(--surface-alternate-2);

      & > .ant-collapse-item {
        border-color: var(--surface-alternate-2);

        & > .ant-collapse-header {
          color: var(--text-dark-high-emphasis);
          background-color: var(--surface-alternate-2);
        }

        & > .ant-collapse-content {
          background-color: var(--surface-alternate);
          border-top: none;
        }

        &:last-child {
          border-radius: 0;

          & > .ant-collapse-header,
          & > .ant-collapse-content {
            border-radius: 0;
          }
        }
      }
    }
  }
}
