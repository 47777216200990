@use 'designSystem2022.module' as ds;

.blockedContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.mainContent {
  flex: 1;
  overflow: auto;

  .content {
    display: flex;
    flex-direction: column;
    gap: 72px;
    padding: 5%;
    min-width: 440px;
    max-width: 1400px;
    margin: 0 auto;

    .topContainer {
      display: flex;
      align-items: center;
      gap: 100px;
      @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
      }

      .topLeftContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .headerTag {
          margin-bottom: var(--spacing-2);
        }
      }
      .topRightContainer {
        display: flex;
        justify-content: center;
        width: 100%;

        .backgroundWrapper {
          width: 100%;
          max-width: 500px;
          padding: 24px;
          border-radius: 6px;
          margin-left: auto;

          @media (max-width: 1000px) {
            margin-left: 0;
          }

          &.tests {
            background: linear-gradient(110.81deg, #fdf7f4 0%, #f9dab3 95.03%);
          }
          &.runs {
            background: linear-gradient(110.81deg, #f5fbff 0%, #b7ddfb 95.03%);
          }
          &.rules {
            background: linear-gradient(110.81deg, #f5effe 0%, #a38cf7 95.03%);
          }

          .videoWrapper {
            width: 100%;
            position: relative;
            box-shadow: var(--shadows-card);

            img: {
              position: absolute;
              top: 0;
              left: 0;
            }

            video {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .bottomContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    gap: var(--spacing-4);

    .blockedCard {
      @include ds.SubtitlesSubtitle-1;
      background-color: white;
      padding: 24px 20px;
      display: flex;
      flex-direction: column;
      gap: var(--spacing-4);
      color: var(--text-light-high-emphasis);
      box-shadow: var(--shadows-card);
      border-radius: 12px;

      .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 40px;
        border-radius: 4px;
        background-color: var(--theme-primary-soft);
        color: var(--theme-primary-dark);
      }

      .cardDescription {
        @include ds.BodyBody-1Paragraph;
        color: var(--text-light-low-emphasis);
      }
    }
  }
}
