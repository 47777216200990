@use 'designSystem2022.module' as ds;

$drawer-header-height: 44px;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--surface-alternate-1);
  height: 50%;
  overflow: hidden;
  position: relative;
}
.ghostFlowTop {
  width: 100%;
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.dependenciesWrapper {
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
  gap: 8px;
  color-scheme: dark;
  flex-grow: 1;
}
.stepsWrapper {
  display: flex;
  position: relative;
  align-items: center;
  gap: 32px;
  margin-left: auto;
  margin-right: 32px;
}
.lowOpacityWrapper {
  opacity: 0.5;
}
.title {
  color: white;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
.drawerHeader {
  @include ds.SubtitlesSubtitle-2;
  color: var(--text-dark-medium-emphasis);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom: 1px solid var(--grayscale-smoke-darken-60);
  height: $drawer-header-height;
}
.drawerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - $drawer-header-height);
}
.drawer {
  :global(.ant-drawer-content) {
    background-color: var(--surface-alternate);
  }
}
.autoScrollSwitchWrapper {
  @include ds.BodyBody-2Regular;
  color: var(--text-dark-medium-emphasis);
  display: flex;
  align-items: center;
  gap: 12px;
}
.resumeFromStepArrow {
  position: absolute;
  top: 25%;
  z-index: 1;
  &:before {
    content: '';
    background-color: white;
    position: absolute;
    top: 0;
    right: 1px;
    transform: translate(50%, -50%);
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 100%;
    border: 5px solid var(--theme-primary-default);
    z-index: 2;
  }
}
.caret {
  color: var(--theme-primary-default);
  position: absolute;
  left: -1px;
  bottom: 0;
  transform: translateY(50%);
}
