@use 'designSystem2022.module' as ds;

.addAssertionCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .addAssertionCardIcon {
    border-radius: 100%;
    background-color: var(--theme-primary-soft);
    color: var(--theme-primary-dark);
    margin-right: var(--spacing-2);
    font-size: 12px;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .addAssertionButton {
    margin-left: auto;
  }
}

.tabs {
  :global(.ant-tabs-nav) {
    margin-bottom: 0 !important;
  }
}

.overviewSection {
  margin-bottom: var(--spacing-6);

  .overviewSectionTitle {
    @include ds.SubtitlesSubtitle-1;
    color: var(--text-light-high-emphasis);
    margin-bottom: var(--spacing-3);
    display: flex;
    align-items: center;
    gap: 8px;

    .questionIcon {
      color: var(--text-light-low-emphasis);
    }
  }

  .showMoreAssertionsLink {
    display: flex;
    justify-content: center;
    margin-top: var(--spacing-3);
  }

  .subActionContainer {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .text {
      @include ds.BodyBody-2Regular;
      display: flex;
      align-items: center;
    }

    .rerecordAndSplitContainer {
      text-align: right;
      display: flex;
      justify-content: flex-end;
    }
  }

  .info {
    color: var(--text-light-medium-emphasis);
    @include ds.BodyBody-2Paragraph;
    white-space: break-spaces;
  }
}

.watchSessionReplay {
  margin-left: auto;
  margin-bottom: var(--spacing-2);
  flex-grow: 0;
  flex-shrink: 0;
}

.container {
  width: 525px;
  color: var(--text-light-medium-emphasis);
  background-color: var(--surface-standard);
  text-align: left;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.containerTop {
  .stepTitle {
    height: 36px;
    margin-bottom: var(--spacing-4);
    flex-shrink: 0;
  }

  .readonlyBanner {
    margin-bottom: var(--spacing-4);
  }

  border-bottom: none !important;
}
