@use 'designSystem2022.module' as ds;

:global {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
}

.pageContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .title {
    font-weight: bold;
  }

  .description {
    font-size: 15px;
    color: #888;
  }

  .checkboxGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    align-items: flex-start;
  }

  .button {
    float: right;
  }
}
