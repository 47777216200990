// There is an edge case when there is no top and bottom flows (only left and right)
// In that case we need to insert a placeholder between these 2 flows and we want to keep the same gap
$flowSectionGap: 24px;

.recorderFlowPreview {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  padding: 0 32px;

  .flowSectionWrapper {
    display: flex;
    align-items: flex-end;

    .flowSection {
      position: relative;
      display: flex;
      align-items: center;
      gap: $flowSectionGap;

      .sectionLine {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 100%;
        height: 1px;
        border-bottom: solid 1px var(--theme-success-default);
      }

      .sectionLineOriginal {
        border-bottom: dashed 1px var(--theme-danger-default);
      }

      .flowSectionItem {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        margin: 32px 0;

        .flowSectionTag {
          position: absolute;
          left: 50%;
          top: -8px;
          transform: translate(-50%, -100%);
        }

        &:before {
          content: '';
          position: absolute;
          left: -4px;
          top: calc(50% - 4px);
          height: 8px;
          width: 8px;
          border-radius: 100%;
          background-color: white;
          border: 1px solid var(--theme-success-default);
          z-index: 1;
        }

        &:after {
          content: '';
          position: absolute;
          right: -4px;
          top: calc(50% - 4px);
          height: 8px;
          width: 8px;
          border-radius: 100%;
          background-color: white;
          border: 1px solid var(--theme-success-default);
          z-index: 1;
        }
      }

      .flowSectionItemOriginal {
        &:before {
          border-color: var(--theme-danger-default);
        }
        &:after {
          border-color: var(--theme-danger-default);
        }
      }
    }

    .flowSectionHasTail {
      flex: 1;
    }
  }

  .flowSectionWrapperHasTail {
    flex: 1;
  }

  .placeholderLine {
    min-width: $flowSectionGap;
    height: 1px;
    background-color: var(--theme-success-default);
  }

  .middleSectionWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .middlesectionTop {
      height: 100%;
      display: flex;
      align-items: flex-end;
    }

    .middleSectionBottom {
      height: 100%;
      display: flex;
      align-items: flex-start;
    }
  }
}
