@use 'designSystem2022.module' as ds;

.bottomPanel {
  display: flex;
  flex-flow: column;
  background-color: var(--surface-alternate-1);
  border-radius: 4px;
  height: 100%;

  &.small {
    --panel-height: 15vh;
  }

  .details {
    flex: 1 1 auto;
    display: flex;
    @include ds.SmallLabel;
    color: var(--text-dark-medium-emphasis);
    margin-bottom: var(--spacing-2);
    b {
      color: var(--text-dark-high-emphasis);
    }

    .left {
      flex: 1 1 50%;

      .appIdentifier {
        color: var(--text-dark-high-emphasis);
      }

      .otherDetails {
        display: flex;
        flex-flow: row wrap;
        gap: var(--spacing-3);
      }
    }
    .right {
      color: var(--text-dark-high-emphasis);
      text-align: right;
    }
  }

  .message {
    @include ds.BodyBody-2Regular;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    flex: 1 1 100%;
    background-color: var(--surface-alternate);
    padding: var(--spacing-2);
    color: var(--text-dark-high-emphasis);
    color-scheme: dark;
  }
}
