@use 'designSystem2022.module' as ds;

.steps {
  padding-left: var(--spacing-2) !important;
  :global {
    .ant-steps-item-icon {
      margin-right: 32px !important;
      margin-top: var(--spacing-2) !important;
    }
    .ant-steps-item-title {
      @include ds.SubtitlesSubtitle-2;
    }
    .ant-steps-item:not(.ant-steps-item-wait)
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: var(--text-light-high-emphasis);
    }
    .ant-steps-item-description {
      padding-bottom: var(--spacing-8) !important;
      padding-top: var(--spacing-2) !important;
      margin-left: 3px;
      margin-right: 3px;
    }

    .ant-steps-item-active .ant-steps-item-icon {
      margin-right: 30px !important;
    }

    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: var(--text-light-low-emphasis);
    }
  }

  .newInteractionContainer {
    padding: var(--spacing-4);
    border-radius: 4px;
    background-color: var(--surface-standard-1);
    box-shadow: var(--shadows-default);
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
  }

  .radioGroup {
    :global(.ant-radio-wrapper) {
      > span:last-child {
        @include ds.BodyBody-2Regular;
        color: var(--text-light-high-emphasis);
      }

      :global(.ant-radio) {
        margin-right: var(--spacing-1);
      }
    }
  }

  .textarea {
    outline: none;
    border-radius: 4px;
    width: 100%;
    height: 60px;
    padding: var(--spacing-2);
    border: 1px solid var(--grayscale-smoke-lighten-80);
    color: var(--text-light-medium-emphasis);

    &:focus {
      color: var(--text-light-high-emphasis);
    }
  }
}
