@use 'designSystem2022.module' as ds;
@use 'mixins';

.contentPayload {
  padding: var(--spacing-4);
  font-size: 12px;
  line-height: '14px';
}

.mediaContent {
  width: 100%;
  border: 1px solid var(--grayscale-smoke-darken-60);
  border-radius: 4px;
}

.viewRaw {
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--theme-primary-default);
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}

.requestLoader {
  margin-top: var(--spacing-8);
}

.requestInfoContainer {
  padding: 20px;
  white-space: 'pre-line';
}

.relaunchButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.requestTabs {
  :global {
    .ant-tabs-nav {
      margin: 0 !important;
      padding: 0 0 0 !important;
    }
  }
}

.headersTabContent {
  .headers {
    .header {
      .key,
      .separator {
        color: var(--theme-primary-default);
        font-weight: bold;
      }
      .value {
        font-family: var(--code-font-family);
      }
    }
  }
}

.collapsibleBlock {
  padding-bottom: 1em;

  & > .collapsibleBlockTitle {
    @include mixins.unstyled-button();
    display: block;
    font-weight: bold;
    color: var(--text-dark-high-emphasis);
    user-select: none;
    width: 100%;
    cursor: pointer;

    &::before {
      content: '▼ ';
      display: inline-block;
      font-size: 8px;
      transform: translateY(-2px);
    }

    &.collapsed::before {
      content: '▶ ';
    }
  }

  & > .collapsibleBlockContent {
    margin-left: var(--spacing-2);
  }
}
