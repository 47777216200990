@use 'designSystem2022.module' as ds;

.alert {
  max-width: 340px;
  border-radius: 4px;
  padding: 4px;

  color-scheme: light;
  color: var(--text-light-high-emphasis);
  background-color: var(--surface-standard-1);
  box-shadow: var(--shadows-tooltip-light);

  h5 {
    @include ds.SubtitlesSubtitle-1;
    color: var(--text-light-high-emphasis);
  }

  p {
    @include ds.BodyBody-1Paragraph;
    color: var(--text-light-medium-emphasis);
  }

  .line {
    height: 4px;
    border-radius: 2px;
    background-color: var(--theme-primary-default);
  }

  .content {
    margin: 12px 12px 8px 12px;
  }

  .buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 16px;
  }

  &.warning {
    .line {
      background-color: var(--theme-warning-default);
    }
  }

  &.attention {
    .line {
      background-color: var(--theme-attention-default);
    }
  }

  &.danger {
    .line {
      background-color: var(--theme-danger-default);
    }
  }

  &.success {
    .line {
      background-color: var(--theme-success-default);
    }
  }

  &.dark {
    color-scheme: dark;
    color: var(--text-dark-high-emphasis);
    background-color: var(--surface-alternate-2);
    box-shadow: var(--shadows-tooltip-dark);

    h5 {
      color: var(--text-dark-high-emphasis);
    }

    p {
      color: var(--text-dark-medium-emphasis);
    }

    &.warning {
      .line {
        background-color: var(--theme-warning-light);
      }
    }

    &.attention {
      .line {
        background-color: var(--theme-attention-light);
      }
    }

    &.danger {
      .line {
        background-color: var(--theme-danger-light);
      }
    }

    &.success {
      .line {
        background-color: var(--theme-success-light);
      }
    }
  }
}

.bottomRight {
  position: absolute;
  bottom: 16px;
  right: 16px;
}
