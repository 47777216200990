@font-face {
  font-family: 'waldo';
  src: url('waldo.eot?6ol9k3');
  src:
    url('waldo.eot?6ol9k3#iefix') format('embedded-opentype'),
    url('waldo.ttf?6ol9k3') format('truetype'),
    url('waldo.woff?6ol9k3') format('woff'),
    url('waldo.svg?6ol9k3#waldo') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='waldo-'],
[class*=' waldo-'],
[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'waldo' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-upload-icon:before {
  content: '\e900';
}
.icon-slide-icon:before {
  content: '\e901';
}
.icon-compare-icon:before {
  content: '\e902';
}
.icon-opacity-icon:before {
  content: '\e903';
}
.icon-uniE904:before {
  content: '\e904';
}
.icon-uniE905:before {
  content: '\e905';
}
.icon-select-icon:before {
  content: '\e906';
}
.icon-arrow-icon:before {
  content: '\e907';
}
.icon-cross-icon:before {
  content: '\e908';
}
.icon-failed-state-icon:before {
  content: '\e909';
}
.icon-recorded-statu-icon:before {
  content: '\e90a';
}
.icon-recording-state-icon:before {
  content: '\e90b';
}
.icon-difference-icon:before {
  content: '\e90c';
}
.icon-stack-icon:before {
  content: '\e90d';
}
.icon-back-icon:before {
  content: '\e90e';
}
.icon-drop-icon:before {
  content: '\e90f';
}
.icon-trash-icon:before {
  content: '\e910';
}
.icon-tap-icon:before {
  content: '\e911';
}
.icon-long-tap-icon:before {
  content: '\e957';
}
.icon-scroll-icon:before {
  content: '\e912';
}
.icon-search-icon:before {
  content: '\e913';
}
.icon-keyboard-icon:before {
  content: '\e914';
}
.icon-drag-icon:before {
  content: '\e915';
}
.icon-compare-icon2:before {
  content: '\e916';
}
.icon-flow-icon:before {
  content: '\e917';
}
.icon-screen-icon:before {
  content: '\e918';
}
.icon-stack-icon2:before {
  content: '\e919';
}
.icon-compare-bottom-icon:before {
  content: '\e91a';
}
.icon-check-icon:before {
  content: '\e91b';
}
.icon-compare-top-icon:before {
  content: '\e91c';
}
.icon-id-icon:before {
  content: '\e91d';
}
.icon-credential-icon:before {
  content: '\e91e';
}
.icon-register-icon:before {
  content: '\e921';
}
.icon-repo-icon:before {
  content: '\e922';
}
.icon-os-icon:before {
  content: '\e923';
}
.icon-selectarrow-icon:before {
  content: '\e924';
}
.icon-help-2-icon:before {
  content: '\e925';
}
.icon-help-1-icon:before {
  content: '\e926';
}
.icon-copy-icon:before {
  content: '\e927';
}
.icon-warning-icon:before {
  content: '\e928';
}
.icon-longarrow-icon:before {
  content: '\e929';
}
.icon-gradle-icon:before {
  content: '\e92a';
}
.icon-account-icon:before {
  content: '\e92b';
}
.icon-nodevices-icon:before {
  content: '\e92c';
}
.icon-dependancie-icon:before {
  content: '\e92d';
}
.icon-bolt-icon:before {
  content: '\e92e';
}
.icon-sadphone2-icon:before {
  content: '\e92f';
}
.icon-sadphone1-icon:before {
  content: '\e930';
}
.icon-team-icon:before {
  content: '\e931';
}
.icon-members-icon:before {
  content: '\e932';
}
.icon-lock-icon:before {
  content: '\e933';
}
.icon-error-icon:before {
  content: '\e934';
}
.icon-org-icon:before {
  content: '\e935';
}
.icon-edit-icon:before {
  content: '\e936';
}
.icon-android-icon:before {
  content: '\e937';
}
.icon-apple-icon:before {
  content: '\e938';
}
.icon-send-icon:before {
  content: '\e939';
}
.icon-card-icon:before {
  content: '\e93a';
}
.icon-build-icon:before {
  content: '\e93b';
}
.icon-arrow-down-small-icon:before {
  content: '\e93c';
}
.icon-star-icon:before {
  content: '\e93d';
}
.icon-script-icon:before {
  content: '\e93e';
}
.icon-dollar-icon:before {
  content: '\e93f';
}
.icon-search-icon1:before {
  content: '\e940';
}
.icon-hide-icon:before {
  content: '\e941';
}
.icon-pending-icon:before {
  content: '\e942';
}
.icon-expand-icon:before {
  content: '\e943';
}
.icon-share-icon:before {
  content: '\e944';
}
.icon-chevron-left-icon:before {
  content: '\e945';
}
.icon-chevron-right-icon:before {
  content: '\e946';
}
.icon-fastlane-icon:before {
  content: '\e947';
}
.icon-sidebar-icon:before {
  content: '\e948';
}
.icon-dependency-icon:before {
  content: '\e949';
}
.icon-integration-icon:before {
  content: '\e94a';
}
.icon-github-icon:before {
  content: '\e94b';
}
.icon-share-icon1:before {
  content: '\e94c';
}
.icon-update-icon:before {
  content: '\e94d';
}
.icon-archive-icon:before {
  content: '\e94e';
}
.icon-minus-icon:before {
  content: '\e94f';
}
.icon-plus-icon:before {
  content: '\e950';
}
.icon-relaunch-icon:before {
  content: '\e951';
}
.icon-shake-icon:before {
  content: '\e952';
}
.icon-calendar-icon:before {
  content: '\e953';
}
.icon-clock-icon:before {
  content: '\e954';
}
.icon-help-icon:before {
  content: '\e955';
}
.icon-notification-icon:before {
  content: '\e956';
}
.icon-dots-icon:before {
  content: '\22ee';
}
.icon-live-icon:before {
  content: '\e958';
}
