@use 'designSystem2022.module' as ds;

:global(.ant-card).card {
  background-color: var(--surface-standard-1);
  border-radius: 4px;
  box-shadow: var(--shadows-default);
  &:not(:first-child) {
    margin-top: var(--spacing-6);
    @media (min-width: 1200px) {
      margin-top: var(--spacing-8);
    }
  }

  :global(.ant-card-head-title) {
    @include ds.HeadingsH6;
    color: var(--text-light-high-emphasis);
  }

  :global(.ant-card-head-title),
  :global(.ant-card-extra) {
    padding-top: var(--spacing-6);
    padding-bottom: var(--spacing-3);
  }

  :global(.ant-card-head) {
    border-bottom: none;
  }

  :global(.ant-card-head-wrapper) {
    border-bottom: 1px solid var(--grayscale-smoke-lighten-90);
  }

  .description {
    @include ds.BodyBody-1Paragraph;
    color: var(--text-light-medium-emphasis);
    padding-bottom: var(--spacing-6);
    white-space: break-spaces;
  }
}
