.editColumn {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
}

.variableElement {
  color: #1b649f;
  background-color: #e8f5f9;
  border-radius: 2px;
  padding: 0px 2px;
  margin: 0px 2px;
  font-weight: 500;
}

.variableStatusCell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--spacing-2);
}
