.container {
  overflow-x: auto;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 16px 0 16px 16px;
  color-scheme: dark;
}

.containerGhost {
  height: 50%;
}

.stepsWrapper {
  display: flex;
  position: relative;
  gap: 32px;
  margin-right: 32px;
}

.dependenciesWrapper {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
}

.banner {
  position: absolute;
  top: 80;
}
