@use 'designSystem2022.module' as ds;

.popover {
  :global {
    .ant-popover-inner {
      box-shadow: var(--shadows-default);
      max-width: 375px;
    }
    .ant-popover-inner-content {
      padding: 0px;
    }
  }

  .tooltipContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: var(--spacing-4);

    .tooltipTitle {
      @include ds.SubtitlesSubtitle-1;
      color: var(--text-light-high-emphasis);
      margin-bottom: var(--spacing-4);
    }

    .tooltipDescription {
      @include ds.BodyBody-2Paragraph;
      color: var(--text-light-medium-emphasis);
      gap: 16px;
      display: flex;
      flex-direction: column;
    }
  }

  .tooltipFooter {
    padding: var(--spacing-2);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: solid 1px var(--grayscale-smoke-lighten-90);
  }
}

.container {
  gap: 24px;

  .deviceLabel {
    display: inline-block !important;

    > div {
      padding-top: 3px;
    }
  }

  .paragraph {
    @include ds.BodyBody-1Regular;
    color: var(--text-light-medium-emphasis);
    margin-bottom: 24px;
  }

  .question {
    @include ds.SubtitlesSubtitle-1;
    color: var(--text-light-high-emphasis);
  }

  .divider {
    margin: var(--spacing-4) 0;
  }

  .optionTitle {
    @include ds.SubtitlesSubtitle-2;
    color: var(--text-light-high-emphasis);
    white-space: break-spaces;
    margin-bottom: var(--spacing-2);
    gap: var(--spacing-1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 24px;

    .suggestedTag {
      display: inline-flex;
    }
  }

  .optionDescription {
    @include ds.BodyBody-2Regular;
    color: var(--text-light-medium-emphasis);
    white-space: break-spaces;
    line-height: 18px;

    .optionDescriptionBaselineTag {
      color: var(--text-light-high-emphasis);
    }
  }
}
