@use 'designSystem2022.module' as ds;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: var(--spacing-8) 0;
  background-color: var(--surface-alternate);
  min-height: 500px;

  .header {
    flex: 0 0 auto;
    margin-top: var(--spacing-4);
    margin-bottom: var(--spacing-8);
    display: flex;
    flex-direction: column;
    align-items: center;

    .headerTitle {
      color: var(--surface-standard-1);
    }

    .headerBottom {
      @include ds.BodyBody-1Paragraph;
      color: var(--text-dark-medium-emphasis);
      margin-bottom: var(--spacing-2);
    }
  }

  .dependenciesContainer {
    flex: 1 1 0;
    height: 0;
    width: 100%;
    max-height: 600px;
    margin-bottom: var(--spacing-10);
    padding: 0px var(--spacing-6);
    max-width: 1800px;
  }

  .buttons {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-4);
  }
}
