.input {
  min-width: 150px;
}

.inputIcon {
  color: var(--text-light-medium-emphasis);
}
.inputIconDark {
  color: var(--text-dark-medium-emphasis);
}
