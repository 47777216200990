.buttons {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  row-gap: 12px;
}

.appTokenBanner {
  margin-top: 24px;

  button {
    min-width: 110px; // We don't want a big size change when the value become 'Copied! ✓'

    .check {
      margin-left: 13px;
      color: var(--theme-success-default);
    }
  }

  code {
    font-family: var(--code-font-family);
    font-weight: bold;
  }
}
