@use 'designSystem2022.module' as ds;

:global(.ant-layout).layout {
  width: 100%;
  min-height: 100%;
  display: flex;
  background-color: var(--surface-standard-1);

  .pageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    box-shadow: var(--shadows-default);
    padding: 24px 32px;
    @include ds.SubtitlesSubtitle-1;
  }

  .content {
    width: 656px;
    padding: 16px;
    padding-top: 48px;
    align-self: center;
  }
}
