.cascader {
  &:global {
    &.ant-select-focused {
      .ant-select-selector {
        --border-color: var(--theme-primary-default);
      }
    }
  }

  :global {
    .ant-select-selector {
      color: var(--text-light-high-emphasis) !important;
      --border-color: var(--grayscale-smoke-lighten-80);
      background-color: transparent !important;
      border-radius: 4px !important;
      border: 1px solid var(--border-color) !important;

      &:hover {
        --border-color: var(--theme-primary-default);
      }
    }

    .ant-select-arrow {
      right: 10px;
      color: var(--text-light-medium-emphasis);
    }

    .ant-select-selection-item {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .ant-select-selection-search {
      padding: 3px 0px;
    }
  }
}
