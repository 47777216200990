@use 'designSystem2022.module' as ds;
@use 'mixins';

.tooltip {
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      flex: 0 0 auto;
    }

    .stepIndex {
      @include ds.SubtitlesSubtitle-2;
      display: flex;
      align-items: center;
      min-height: var(--spacing-6);
      color: var(--text-dark-high-emphasis);
      margin-right: var(--spacing-2);
    }

    .assertionStatus {
      @include ds.CaptionSemi-Bold;
      display: flex;
      align-items: center;

      padding-left: var(--spacing-2);
      padding-right: var(--spacing-2);
      border-left: 1px solid var(--grayscale-smoke-darken-60);
    }

    .viewButton {
      margin-left: var(--spacing-6);
    }
  }

  .info {
    display: flex;
    align-items: center;
    min-height: 36px;
    color: var(--text-dark-low-emphasis);
    gap: var(--spacing-2);

    .interactionType {
      color: var(--text-dark-medium-emphasis);
      font-weight: 500;
      white-space: nowrap;
    }

    code {
      color: var(--text-dark-medium-emphasis);
    }
  }

  .adminInfo {
    color: var(--text-dark-low-emphasis);
    strong {
      color: var(--text-dark-medium-emphasis);
    }
  }
}
