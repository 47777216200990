@use 'designSystem2022.module' as ds;
@use 'mixins';

.sidebarContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: var(--sidebar-width);
  background-color: white;
  border-right: 1px solid var(--grayscale-smoke-lighten-90);
  transition: 0.3s ease;

  &.dark {
    background-color: var(--surface-alternate-1);
    border-right: 1px solid var(--grayscale-smoke-darken-60);
  }

  .sidebarSections {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    color: var(--text-light-low-emphasis);
    overflow-y: auto;
    transition: 0.3s ease;

    &.dark {
      color: var(--text-dark-low-emphasis);
      color-scheme: dark;
    }

    .sidebarBottomSections {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2);
      padding: var(--spacing-4) var(--spacing-2);
    }

    .sidebarSection {
      padding: var(--spacing-4) var(--spacing-2);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2);
      border-bottom: 1px solid var(--grayscale-smoke-lighten-90);
      transition: 0.3s ease;

      &.dark {
        border-bottom: 1px solid var(--grayscale-smoke-darken-60);
      }

      &:last-child {
        border-bottom: none !important;
      }

      .sidebarSectionTitle {
        @include ds.CaptionDefault;
        margin-bottom: var(--spacing-2);
        padding: 0 var(--spacing-2);
      }
    }
  }
}
