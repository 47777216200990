@use 'designSystem2022.module' as ds;

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-6);
  margin: auto;
  padding: var(--spacing-6);
  width: 100%;
  max-width: 1100px;
  min-width: 350px;

  .errorBannersList {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);

    .errorCode {
      margin-left: var(--spacing-2);
      color: var(--theme-danger-default);
      font-weight: 600;
    }
  }

  > h4,
  p {
    margin: 0;
  }

  .backButton {
    margin-right: auto;
  }

  .caption {
    @include ds.BodyBody-1Paragraph;
    color: var(--text-light-medium-emphasis);
  }

  .uploaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-4);
    margin-top: var(--spacing-6);
    @media (max-width: 1024px) {
      flex-direction: column;
      width: 100%;
    }

    .leftContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: var(--spacing-8);

      .notReadyButtons {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: var(--spacing-2);
      }
    }

    .rightContainer {
      border-radius: 4px;
      box-shadow: var(--shadows-card);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 250px;
      border: 1px dashed var(--grayscale-smoke-lighten-60);
      background-color: var(--surface-standard-0);
      position: relative;
      transition: 0.3s ease;

      &.rightContainerHover {
        &:hover {
          border: 1px dashed var(--theme-primary-default);
          background-color: var(--theme-primary-soft);
        }
      }

      .inputFileUpload {
        display: none;
      }

      &.dragActive {
        border: 1px dashed var(--theme-primary-default);
        background-color: var(--theme-primary-soft);
      }

      .dropZone {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .dndContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--spacing-4);
        padding: var(--spacing-6);
        cursor: pointer;

        img {
          width: 72px;
        }

        .dndExplain {
          @include ds.BodyBody-1Regular;
          color: var(--text-light-medium-emphasis);
          white-space: break-spaces;
          strong {
            font-weight: 600;
          }
        }
      }

      .verifyingContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        white-space: break-spaces;
        color: var(--text-light-medium-emphasis);
        font-weight: 500;
        font-size: 14;
        gap: var(--spacing-4);
      }

      label {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .progressContainer {
          width: 100%;
          padding: var(--spacing-6);

          .progressTopContainer {
            @include ds.BodyBody-1Medium;
            width: 100%;
            margin-bottom: var(--spacing-2);
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: var(--text-light-medium-emphasis);
          }
        }
      }
      .disabled {
        pointer-events: none;
      }
    }
  }
}

.docItems {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  margin: var(--spacing-6) 0;
  gap: var(--spacing-4);

  .docCard {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);
    box-shadow: var(--shadows-card);
    background-color: var(--surface-standard-1);
    padding: var(--spacing-4);
    border-radius: 6px;
    overflow: hidden;
    transition: 0.3s ease;

    img {
      width: 40px;
    }

    &:hover {
      box-shadow: var(--shadows-card-hover);
    }

    .docCardInfo {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-1);

      .docCardTitle {
        @include ds.BodyBody-2Medium;
        color: var(--text-light-high-emphasis);
      }

      .docCardLanguage {
        @include ds.SmallHelperDefault;
        color: var(--text-light-medium-emphasis);
      }
    }
  }
}
