@use 'designSystem2022.module' as ds;

.pickValueDescription {
  @include ds.BodyBody-2Paragraph;
  margin-bottom: var(--spacing-2);
  color: var(--text-light-medium-emphasis);
  white-space: normal;
}

.pickerDefaultValues {
  color: var(--theme-panam-blue-default);
}

.baselineValuesDescription {
  @include ds.CaptionDefault;
  color: var(--text-light-medium-emphasis);
  margin-bottom: var(--spacing-2);
}

.baselineValues {
  margin-bottom: var(--spacing-6);
}
