.apiInteractionContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-2);
  width: 100%;

  .apiInteractionStatusCode {
    flex: 0 0 auto;
  }

  .apiInteractionText {
    flex: 1 1 0;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}
