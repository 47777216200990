@use 'designSystem2022.module' as ds;

.appflowModeBanner {
  margin-bottom: var(--spacing-4);
  padding: var(--spacing-2) var(--spacing-2) var(--spacing-2) var(--spacing-4);
}
.menu {
  :global(.ant-list-item) {
    width: 100%;
    padding: 0;
    border-bottom-color: var(--grayscale-smoke-lighten-90) !important;

    &:not(:last-child) {
      .menuItemWrapper {
        margin-bottom: var(--spacing-2);
      }
    }

    &:not(:first-child) {
      .menuItemWrapper {
        margin-top: var(--spacing-2);
      }
    }

    .menuItemWrapper {
      height: 72px;
      max-height: 72px;
      width: 100%;
      border-color: transparent !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      padding-left: var(--spacing-3);
      padding-right: var(--spacing-3);
      gap: var(--spacing-3);
      border-radius: 8px;

      &:not(:hover) {
        background-color: transparent !important;
      }

      :global {
        .ant-list-item-meta {
          align-items: center;

          .ant-list-item-meta-content {
            text-align: left;

            .ant-list-item-meta-title {
              @include ds.BodyBody-2Medium;
              color: var(--text-light-high-emphasis);
            }
            .ant-list-item-meta-description {
              @include ds.BodyBody-2Paragraph;
              color: var(--text-light-medium-emphasis);
            }

            .ant-list-item-meta-title,
            .ant-list-item-meta-description {
              overflow-x: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .chevronIcon {
        color: var(--text-light-low-emphasis);
      }

      &:disabled {
        background-color: transparent !important;
        opacity: 0.5;
        .chevronIcon {
          display: none;
        }
      }

      .listItemIcon {
        width: 36px;
        height: 36px;
        min-width: 36px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.editInteraction {
          color: var(--secondary-theme-deep-blue-dark);
          background-color: var(--special-opacity-deep-blue);
        }
        &.addStep {
          color: var(--theme-success-dark);
          background-color: var(--special-opacity-success);
        }
        &.removeStep {
          color: var(--theme-danger-dark);
          background-color: var(--special-opacity-danger);
        }
        &.rerecord {
          color: var(--theme-warning-dark);
          background-color: var(--special-opacity-warning);
        }
        &.manageVariables {
          color: var(--theme-pearl-dark);
          background-color: var(--special-opacity-pearl);
        }
        &.splitTest {
          color: var(--secondary-theme-deep-cyan-dark);
          background-color: var(--special-opacity-deep-cyan);
        }
      }
    }
  }
}

.interactionSelectorContainer {
  white-space: normal;
  gap: 36px;
}
