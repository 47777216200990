@use 'designSystem2022.module' as ds;

.tooltip {
  width: 375px;
  :global(.ant-popover-inner) {
    box-shadow:
      0px 0px 1px rgba(108, 117, 241, 0.24),
      0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  }
  :global(.ant-popover-inner-content) {
    padding: 0;
  }
}

.tooltipContent {
  overflow: hidden;
  padding: 16px;
}

.tooltipRow {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;
  color: var(--text-light-medium-emphasis);
}

.idIcon {
  margin-right: 8px;
}

.versionId {
  @include ds.CaptionDefault;
  color: var(--text-light-medium-emphasis);
}

.tooltipFooter {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--grayscale-smoke-lighten-80);
  padding-top: 8px;
}

.wrapper {
  @include ds.SpecialCodeSmall;
  display: flex;
  width: 100%;
  height: 24px;
}
