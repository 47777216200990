@use 'designSystem2022.module' as ds;

.dependenciesModal {
  &:global(.ant-modal) {
    width: calc(100vw - 100px) !important;
    max-width: 1300px;

    :global(.ant-modal-body) {
      .modalContent {
        height: 70vh;
        max-height: 700px;
      }
    }
  }
}
