@use 'designSystem2022.module' as ds;

.layout {
  width: 100%;
  height: 100%;
}

.content {
  overflow: auto;
}

.newRuleContainer {
  margin-bottom: 24px;
}

.centeredContent {
  width: 630px;
  margin: auto;
  padding-top: 32px;
}

.targetCard {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.targetItemContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 16px;
}

.ruleName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
  color: var(--text-light-high-emphasis);
}

.ruleNameWrapper {
  display: inline-flex;
  width: 100%;
}

.link {
  &:hover {
    color: var(--theme-primary-default);
    transition: 0.3s ease;
  }
}

.input {
  height: 36px;
  border: 1px solid var(--grayscale-smoke-lighten-80);
}

.select {
  min-width: 150px;
}

.hint {
  font-size: 13px;
  color: var(--grayscale-smoke-default);
  margin-top: 8px;
}

.slackDivider {
  margin: 8px 0;
}

.deleteButton {
  align-self: center;
}

.targetWhere {
  @include ds.BodyBody-2Medium;
  color: var(--text-light-medium-emphasis);
  min-width: 36px;
  text-align: right;
}

.addNewTestsContainer {
  margin-top: 24px;
  margin-bottom: 8px;
}

.schedulingContainer {
  width: 100%;
}
