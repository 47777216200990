.controlsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 415px;
  color-scheme: dark;
}
.topControls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--spacing-4);
}
.bottomControls {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
  padding-bottom: var(--spacing-4);
}
.select {
  width: 100%;
  :global {
    .ant-select-selector {
      height: 36px;
      color: var(text-dark-high-emphasis);
      background-color: transparent !important;
      border: 1px solid var(--grayscale-smoke-darken-40) !important;
      font-size: 13px;
    }
  }
}
.selectDropdown {
  background-color: var(--surface-alternate-1);
}
.selectOption {
  :global {
    .ant-select-item-option {
      color: white !important;
    }
    .ant-select-item-option:hover {
      background-color: var(--special-hover-dark) !important;
    }
    .ant-select-item-option-selected {
      background-color: var(--special-hover-dark) !important;
    }
    .ant-select-item-option-active {
      background-color: var(--special-hover-dark) !important;
    }
  }
}
.versionsSelect {
  width: 100%;
  margin-bottom: 32px !important;
}

.proxyCheckbox {
  &:global(.ant-checkbox-wrapper) {
    padding: var(--spacing-4);
    padding-bottom: 0;
    color: var(--text-dark-high-emphasis);
    font-size: 13px;
  }

  :global(.ant-checkbox-inner) {
    background-color: var(--grayscale-smoke-darken-60);
    border-color: var(--grayscale-smoke-darken-40);
  }
}
