@use 'designSystem2022.module' as ds;

.onboardingAccountInfo {
  .description {
    @include ds.BodyBody-1Paragraph;
    color: var(--text-light-medium-emphasis);
    margin-bottom: var(--spacing-8);
  }

  .buttons {
    margin-top: var(--spacing-8);
    display: flex;
    justify-content: flex-end;
  }

  .fieldGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    column-gap: var(--spacing-8);
    row-gap: var(--spacing-8);

    :global(.ant-form-item) {
      margin: 0 !important;
    }

    .allColumns {
      grid-column: 1 / 3;
    }
  }

  .discoverableFormItem {
    margin-top: var(--spacing-4);

    .discoverableLabel {
      @include ds.BodyBody-2Regular;
      color: var(--text-light-high-emphasis);
      margin-right: 6px;

      .emailDomain {
        @include ds.BodyBody-2Medium;
      }
    }

    .helpIcon {
      color: var(--text-light-low-emphasis);
    }
  }
}
