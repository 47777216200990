@use 'designSystem2022.module' as ds;

$deviceHorizontalPadding: 32px;

.container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div:first-child {
    width: calc(max(30%, 300px) + 2 * $deviceHorizontalPadding);
    padding: 0 $deviceHorizontalPadding;
    flex: unset;
  }

  h3 {
    color: var(--text-dark-high-emphasis);
    @include ds.HeadingsH5;
    margin: 0;
  }

  h4 {
    color: var(--text-dark-high-emphasis);
    @include ds.BodyBody-1Medium;

    display: flex;
    gap: var(--spacing-4);
    margin-bottom: var(--spacing-4);
    align-items: center;
  }

  p {
    @include ds.BodyBody-1Paragraph;
    color: var(--text-dark-medium-emphasis);
    white-space: pre-line;
    margin: 0;
  }

  b {
    font-weight: 700;
  }
}

.validation {
  overflow-y: auto;
  flex: 1 1 0%;

  > div {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: var(--spacing-4);
    padding: var(--spacing-4) 0;

    width: 50vw;
    max-width: min(800px, 90%);

    > a {
      margin: 0 auto;
    }

    > *:first-child {
      margin-top: auto;
    }

    > *:last-child {
      margin-bottom: auto;
    }
  }
}

.questions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  margin-top: var(--spacing-2);
  width: 100%;
  align-items: flex-start;

  > div {
    width: 100%;
    background-color: var(--surface-alternate-1);
    border-radius: 4px;
    border: 1px solid var(--grayscale-smoke-darken-60);

    > h4 {
      margin: var(--spacing-4);
    }
    > p {
      margin: 0 var(--spacing-4) var(--spacing-6) var(--spacing-4);
    }

    hr {
      border-style: solid;
      border-color: var(--grayscale-smoke-darken-60);
      border-width: 1px 0 0 0;
      margin: 0;
    }
  }

  :global {
    .ant-collapse {
      background-color: transparent;
      border: none;
    }

    .ant-collapse-item {
      border: none !important;
    }

    .ant-collapse-content {
      background-color: unset;
      border: none !important;
    }

    .ant-collapse-content-box {
      padding: 0 var(--spacing-4) var(--spacing-3) var(--spacing-4);
    }

    .ant-collapse-header {
      padding: var(--spacing-3) var(--spacing-4) !important;

      .fa-chevron-down {
        transition: transform 0.3s ease;
      }

      &[aria-expanded='true'] {
        i.fa-chevron-down {
          transform: rotate(180deg);
        }
      }
    }

    .ant-collapse-header-text {
      @include ds.BodyBody-2Medium;

      display: flex;
      align-items: center;
      color: var(--text-dark-high-emphasis);

      i:last-child {
        margin-left: auto;
      }
    }
  }
}
