@use 'designSystem2022.module' as ds;

.layout {
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 350px;
  .content {
    flex: 1;
    overflow: auto;

    .rulesList {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(410px, 1fr));
      gap: var(--spacing-6);
      padding: var(--spacing-6);

      .cardContent {
        min-height: 200px;
        height: 100%;
        border-radius: 6px;
        background-color: white;
        transition: box-shadow 0.3s ease-in-out;
        box-shadow: var(--shadows-default);
        padding: var(--spacing-4);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .cardContentTop {
          width: 100%;
          overflow: hidden;

          .cardHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: var(--spacing-3);

            .iconContainer {
              height: var(--spacing-8);
              width: var(--spacing-8);
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 100%;
            }

            .buttonsContainer {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }
          }

          .label {
            @include ds.SubtitlesSubtitle-1;
            color: var(--text-light-high-emphasis);
            margin-bottom: var(--spacing-2);
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .description {
            @include ds.BodyBody-2Paragraph;
            margin-bottom: var(--spacing-6);
            color: var(--text-light-medium-emphasis);
            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}
