@use 'designSystem2022.module' as ds;

.sessionLoadingContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px;
  gap: var(--spacing-4);
  max-width: 1200px;
  min-width: 400px;

  h5 {
    margin: var(--spacing-4) 0;
    color: var(--text-dark-high-emphasis);
  }

  .cardsContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: var(--spacing-4);

    .card {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-4);
      padding: var(--spacing-6);
      border-radius: 4px;
      background-color: var(--surface-alternate-1);
      border: 1px solid var(--grayscale-smoke-darken-60);

      i {
        color: var(--theme-success-light);
      }

      &:first-child {
        i {
          color: var(--theme-primary-light);
        }
      }

      &:last-child {
        i {
          color: var(--secondary-theme-pink-light);
        }
      }

      .cardTop {
        display: flex;
        align-items: center;
        gap: var(--spacing-4);

        .cardTitle {
          @include ds.BodyBody-1Medium;
          color: var(--text-dark-high-emphasis);
        }
      }

      .cardText {
        @include ds.BodyBody-1Paragraph;
        color: var(--text-dark-medium-emphasis);
      }
    }
  }
}
