.tabsButtons {
  flex: 0 0 auto;
  :global(.ant-tabs-nav) {
    margin-bottom: 0 !important;
  }

  :global(.ant-tabs-content-holder) {
    display: none;
  }

  .tabLabel {
    display: flex;
    align-items: center;
    gap: var(--spacing-2);
  }
}
