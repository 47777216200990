.grid {
  grid-template-columns: 80px 110px 1fr;

  &.showApplicationColumn {
    grid-template-columns: 80px 110px 110px 1fr;
  }
}

.status_WARNING {
  color: var(--theme-warning-light);
}
.status_DANGER {
  color: var(--theme-danger-light);
}
