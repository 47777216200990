@use 'mixins';

.tabs {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 4px;
  padding: 8px 12px;
  background-color: var(--surface-alternate);
  border-bottom: 1px solid var(--grayscale-smoke-darken-60);
  overflow-x: auto;
  color-scheme: dark;

  @include mixins.no-scrollbars();

  &.ant-tabs-top .ant-tabs-nav {
    margin: 0;
  }

  .tab {
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 8px;
    border-radius: 4px;
    height: 32px;
    color: var(--text-dark-high-emphasis);
    flex-shrink: 0;
    white-space: nowrap;

    &.selected {
      background-color: var(--special-opacity-white-10);
    }

    &:hover {
      background-color: var(--special-opacity-white-10);
    }

    .customDeviceLabel {
      background-color: transparent !important;
      padding: 0;
    }
  }
}

.helperContent {
  white-space: break-spaces;
}
