@use 'designSystem2022.module' as ds;

.nodeTitle {
  font-family: var(--code-font-family);
  font-weight: 400;
  background-color: var(--surface-alternate-1);
  padding: var(--spacing-3);
  font-size: var(--font-size-medium);
  color: var(--special-pure-white);
  border-bottom: 1px solid;
  border-color: var(--grayscale-smoke-darken-60);
}

.nodeAttributes {
  max-height: 250px;
  overflow: scroll;
}

.bestPredicatesPopover {
  max-width: 450px;
}

.attributesTitle {
  margin-top: 10px;
  color: var(--special-pure-white);
  padding-left: var(--spacing-2);
}

.message {
  padding: 0;
  margin-top: var(--spacing-2);
}
.emptyImage {
  margin-bottom: var(--spacing-6);
}
