@use 'designSystem2022.module' as ds;

h4 {
  margin-bottom: var(--spacing-8);
}

p.subtitle {
  @include ds.BodyBody-1Paragraph;
  color: var(--text-light-medium-emphasis);
  margin-bottom: var(--spacing-6);
}

.appType {
  margin-right: auto;
  display: flex !important;

  > label {
    display: flex;
    align-items: center;
    padding-right: var(--spacing-4);

    @include ds.SubtitlesSubtitle-2;
  }

  :global {
    .ant-radio {
      top: unset !important;
    }
    .ant-radio-wrapper {
      margin: 0 !important;
    }
  }
}
