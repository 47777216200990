@use 'designSystem2022.module' as ds;

.interactionCard {
  box-shadow: var(--shadows-default);
  border-radius: 6px;
  padding: var(--spacing-3);
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
  background-color: var(--surface-standard-1);
}

.apiInteractionContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-1);
  .method {
    flex: 0 0 auto;
  }
  .url {
    font-style: italic;
    flex: 1 1 0;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.apiInteractionTooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .list {
    padding-inline-start: var(--spacing-6);
    .headersRow {
      display: flex;
      flex-direction: row;
      gap: var(--spacing-1);
      align-items: flex-start;
      margin-left: 8px;
      .subTitle {
        font-weight: 600;
      }
      .value {
        word-break: break-word;
      }
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-1);
  }
  .title {
    font-weight: 800;
  }
}

.pickerDefaultValues {
  color: var(--theme-panam-blue-default);
}

.iconResults {
  margin-right: var(--spacing-2);
}

.variableTag {
  color: var(--grayscale-smoke-darken-60) !important;
  background-color: var(--grayscale-smoke-lighten-90) !important;
  flex-shrink: 1 !important;
}
