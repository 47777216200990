@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('FiraCode-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('FiraCode-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('FiraCode-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('FiraCode-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('FiraCode-Bold.ttf') format('truetype');
}
