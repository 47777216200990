@use 'designSystem2022.module' as ds;
@use 'src/app/runs/runStudio/video/runStudioVideoControls.module.scss' as videoControls;

.replayViewer {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  .breadcrumb {
    color: var(--text-dark-medium-emphasis) !important;

    &:hover {
      color: var(--text-dark-high-emphasis) !important;
    }
  }

  .lastBreadcrumb {
    color: var(--text-dark-high-emphasis) !important;
    display: block;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .header {
    gap: var(--spacing-6);

    :global {
      .ant-breadcrumb {
        ol {
          max-width: 100%;
          flex-wrap: nowrap;

          li:last-child {
            min-width: 0px;
            span:first-child {
              min-width: 0px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .ant-breadcrumb-separator > i {
        color: var(--text-dark-low-emphasis) !important;
      }
    }
  }

  .devicePane {
    > div {
      width: 100%;
      height: 100%;
    }
  }

  .allotmentContainer {
    flex: 1 1 0%;
  }

  .videoAndTabs {
    background-color: var(--surface-alternate);
    color: var(--text-dark-medium-emphasis);
    color-scheme: dark;
    isolation: isolate;

    .videoTopAndBottomSpacer {
      // Adding anything to the top or bottom of the video container make it grow by var(--spacing-3) as it is the gap
      // between flex elements. We want var(--spacing-4) of empty space here so we add the difference.
      height: calc(var(--spacing-4) - var(--spacing-3));
    }

    :global(.ant-checkbox-wrapper) {
      color: var(--text-dark-medium-emphasis);
    }

    .darkSpinner {
      background-color: var(--surface-alternate);
    }

    .tabsPane {
      transition: transform ease 0.3s;
      display: flex;
      flex-flow: column nowrap;

      .tabsContent {
        flex: 1 1 100%;
        overflow: hidden;
        position: relative;

        .timelineTab {
          padding: 0 !important;
        }

        .adminTab {
          overflow: auto;
        }

        .logsTab {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: var(--surface-alternate);
          z-index: -1;
          padding-left: var(--spacing-4);

          /* All elements are on the page and 'visible' at the same time but stacked for a single tab to be
           * visually there. The reasons:
           *  - We want all scroll positions to be kept without jumps when switching tabs
           *     => We can't unmount the elements
           *  - We want the virtual list to keep it's size and need no new calculations when switching tabs
           *     => We can't display: none and need to play on visibility
           *  - We want no animations on switching tabs due to visibility or opacity changing
           *     => We need the elements always visible but stacked and hiding each other
           */
          &.visible {
            z-index: 0;
          }

          .actions {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 8px;
          }

          .logsTabContent {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;

            .actionsWrapper {
              width: 100%;
              display: flex;
              align-items: center;
              gap: 8px;
              margin: var(--spacing-3) 0;

              :last-child {
                margin-left: auto;
              }
            }
          }

          .logLines {
            @include ds.SpecialCodeMedium;
            flex: 1 1 100%;
            user-select: none;

            .logLine {
              border-top: 2px solid transparent;
              border-radius: 5px;
              white-space: nowrap;
              cursor: pointer;

              .status {
                padding: 0 4px;
              }

              &.odd {
                background-color: #2f3237;
              }

              &.nextLine {
                border-top: 2px solid var(--theme-attention-default);
              }

              &.status_warning {
                color: var(--theme-warning-light);
              }
              &.status_attention {
                color: var(--theme-attention-light);
              }
              &.status_danger {
                color: var(--theme-danger-light);
              }

              &.selected {
                background-color: var(--grayscale-smoke-darken-40);

                &.oddx {
                  background-color: #2f3237;
                }
              }
            }
          }
        }

        .elementsTab {
          @extend .logsTab;
          padding-left: 0;
        }

        .infoTab {
          @extend .logsTab;
          padding-right: var(--spacing-4);
        }

        .selectTreeToInspect {
          margin: auto;
          max-width: 420px;

          .satelliteIcon {
            margin-bottom: var(--spacing-8);
          }
        }

        .proxyDeactivated {
          margin: auto;
          max-width: 420px;

          .prohibitedIcon {
            margin-bottom: var(--spacing-8);
          }
        }
      }
    }
  }

  .controlsContainer {
    position: relative;
  }

  .devicePane,
  .tabsPane {
    transition: all 0.15s ease-in-out;
    will-change: width;
  }

  .videoAndTabs:global(.split-view-sash-dragging) .devicePane,
  .videoAndTabs:global(.split-view-sash-dragging) .tabsPane {
    /* disable animation while sash dragging */
    transition: none;
  }

  .elementsSpinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
