@use 'designSystem2022.module' as ds;

:global(.ant-form-item).appTypeContainer {
  :global(.ant-form-item-control-input) {
    min-height: initial;

    .appType {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: var(--text-light-low-emphasis);

      .osIcon {
        font-size: 14px;
        margin-right: 5px;
        ::before {
          color: var(--text-light-low-emphasis);
        }
      }
    }
  }
}

:global(.ant-form-item).appNameContainer {
  max-width: 288px;
  padding-top: var(--spacing-6);
}

.appIdColumn {
  @include ds.BodyBody-2Paragraph;
  color: var(--text-light-medium-emphasis);
  display: flex;
  align-items: center;
}
