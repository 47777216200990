@use 'designSystem2022.module' as ds;

// Forcing the line height because all interactions icons
// do not have the same height (some are 16px svgs, some are 19.5 fa icons
// by setting the line height for both the right container and the
// interaction title we ensure that they will be aligned.
// It is not possible to have them in the same flex otherwise
// we would not be able to wrap the interaction content when needed
$topLineHeight: 20px;

.timelineCardWrapper {
  height: 66px;
  flex: none;
  display: flex;
  padding: var(--spacing-3) var(--spacing-5);
  border: 1px solid transparent;
  border-top: 1px solid var(--grayscale-smoke-darken-60);

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: hidden;

  @include ds.BodyBody-2Regular;
  color: var(--text-dark-high-emphasis);

  &.selected {
    background-color: var(--surface-alternate-2);

    .buttons {
      visibility: visible;
    }
  }

  &:hover {
    .buttons {
      visibility: visible;
    }
  }

  &.next {
    border-top: 1px solid var(--theme-attention-default);
  }

  .timelineCardGenericEvent {
    width: fit-content;

    .timelineCardGenericTag {
      cursor: pointer;
      max-width: 100%;
    }
  }

  .content {
    min-width: 0;
    flex: 1 1 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-2);
    overflow: hidden;

    .system {
      b,
      strong {
        font-weight: bold;
      }
      &.error {
        color: var(--theme-danger-light);

        .errorBlock {
          font-family: var(--code-font-family);
          white-space: pre-wrap;
          word-break: break-all;
          margin: 0.5em 0;
          overflow-y: scroll;
          max-height: 200px;
        }
      }
    }
  }

  .rightContainer {
    flex: 0 0 auto;
    @include ds.BodyBody-2Regular;
    color: var(--text-dark-low-emphasis);
    padding: 0 16px;
    line-height: $topLineHeight;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-2);
  }

  .annotationIcon {
    color: var(--theme-primary-light);
  }

  .interactionTitle {
    @include ds.BodyBody-2Semi-Bold;
    line-height: $topLineHeight;
    white-space: nowrap;
  }

  .interactionContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1 0;
    max-height: 100%;
    min-width: 0;
  }

  .interactionValue {
    color: var(--text-dark-low-emphasis);
    @include ds.BodyBody-2Regular;
    min-width: 0;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .interactionIcon {
    border-radius: 6px;
    background-color: var(--surface-alternate-2);
    width: 28px;
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .interactionIcon,
  .interactionValue,
  .interactionTitle {
    &.error {
      color: var(--theme-danger-default);
    }
  }

  .appFlow {
    display: flex;
    gap: var(--spacing-2);
    align-items: center;
    justify-content: flex-start;
    .appFlowText {
      @include ds.BodyBody-2Medium;
    }
  }

  .buttons {
    visibility: hidden;
    flex-direction: row;
    gap: var(--spacing-2);
  }
}

.timelineCardClickable {
  cursor: pointer;
  &:hover {
    border: 1px solid var(--theme-primary-default);
  }
}

svg.interactionIcon {
  padding: 6px;
}
