@use 'designSystem2022.module' as ds;

.descriptionRow {
  @include ds.BodyBody-1Paragraph;
  color: var(--text-light-medium-emphasis);
  padding-bottom: var(--spacing-4);
}

.uploadTokenColumn {
  @include ds.BodyBody-2Paragraph;
  color: var(--text-light-medium-emphasis);
  display: flex;
  align-items: center;
}

.rotateTokenModal {
  :global(.ant-modal-content) {
    .rotateTokenModalContainer {
      white-space: break-spaces;
      @include ds.BodyBody-1Regular;
      line-height: 22px;
      color: var(--text-light-high-emphasis);
    }
  }
}
