@use 'designSystem2022.module' as ds;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  overflow: auto;
  flex-flow: column nowrap;
  gap: var(--spacing-3);

  > * {
    flex: 0 0 auto;
  }

  .live {
    @include ds.ButtonDefault;
    color: var(--theme-danger-light);
  }
}
