/***************************************************************************
 * SASS Mixins and variables for our design system
 ***************************************************************************/

$spacing-1Px: 4;
$spacing-2Px: 8;
$spacing-3Px: 12;
$spacing-4Px: 16;
$spacing-5Px: 20;
$spacing-6Px: 24;
$spacing-8Px: 32;
$spacing-9Px: 36;
$spacing-10Px: 40;
$spacing-12Px: 48;
$spacing-14Px: 56;
$spacing-16Px: 64;

@mixin HeadingsH1 {
  font-family: var(--default-font-family);
  font-size: 93px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
}

@mixin HeadingsH2 {
  font-family: var(--default-font-family);
  font-size: 58px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
}

@mixin HeadingsH3 {
  font-family: var(--default-font-family);
  font-size: 46px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@mixin HeadingsH4 {
  font-family: var(--default-font-family);
  font-size: 33px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
}

@mixin HeadingsH5 {
  font-family: var(--default-font-family);
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@mixin HeadingsH6 {
  font-family: var(--default-font-family);
  font-size: 19px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
}

@mixin SubtitlesSubtitle-1 {
  font-family: var(--default-font-family);
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
}

@mixin BodyBody-1Semi-Bold {
  font-family: var(--default-font-family);
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
}

@mixin BodyBody-1Medium {
  font-family: var(--default-font-family);
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
}

@mixin BodyBody-1Paragraph-Italic {
  font-family: var(--default-font-family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.47;
  letter-spacing: 0.25px;
}

@mixin BodyBody-1Italic {
  font-family: var(--default-font-family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.25px;
}

@mixin BodyBody-1Paragraph {
  font-family: var(--default-font-family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: 0.25px;
}

@mixin BodyBody-1Regular {
  font-family: var(--default-font-family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
}

@mixin SpecialCodeMedium {
  font-family: var(--code-font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@mixin SubtitlesSubtitle-2 {
  font-family: var(--default-font-family);
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
}

@mixin BodyBody-2Semi-Bold {
  font-family: var(--default-font-family);
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
}

@mixin ButtonDefault {
  font-family: var(--default-font-family);
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}

@mixin BodyBody-2Medium {
  font-family: var(--default-font-family);
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
}

@mixin BodyBody-2Paragraph-Italic {
  font-family: var(--default-font-family);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.54;
  letter-spacing: 0.25px;
}

@mixin BodyBody-2Italic {
  font-family: var(--default-font-family);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.25px;
}

@mixin BodyBody-2Regular {
  font-family: var(--default-font-family);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
}

@mixin BodyBody-2Paragraph {
  font-family: var(--default-font-family);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0.25px;
}

@mixin CaptionSemi-Bold {
  font-family: var(--default-font-family);
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
}

@mixin CaptionDefault {
  font-family: var(--default-font-family);
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
}

@mixin SpecialCodeSmall {
  font-family: var(--code-font-family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@mixin SmallLabel {
  font-family: var(--default-font-family);
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}

@mixin SmallHelperDefault {
  font-family: var(--default-font-family);
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
}

@mixin SmallHelperMulti-line {
  font-family: var(--default-font-family);
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.25px;
}
