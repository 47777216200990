@use 'designSystem2022.module' as ds;
@use 'mixins';

.logsTable {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  border: solid 1px var(--grayscale-smoke-darken-60);
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-3);
  position: relative;

  .headers {
    @include ds.BodyBody-2Medium;
    color: var(--text-dark-medium-emphasis);
    background-color: var(--surface-alternate-2);
    min-height: 36px;
    align-items: center;
    width: 100%;
    padding: 0 var(--spacing-6) 0 var(--spacing-3);
    display: grid;
    column-gap: var(--spacing-3);
    text-align: left;
  }

  .logLines {
    @include ds.SpecialCodeMedium;
    color: var(--text-dark-high-emphasis);
    width: 100%;
    flex: 1;
    user-select: none;
    overflow-y: auto;
    color-scheme: dark;
    outline: none;

    .virtualList {
      width: 100%;
      position: relative;

      .virtualRow {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;

        .logLine {
          padding: 0 var(--spacing-3);
          height: 40px;
          display: grid;
          column-gap: var(--spacing-3);
          border-top: 1px solid var(--grayscale-smoke-darken-60);
          align-items: center;

          &.nextLine {
            border-top: 1px solid var(--theme-attention-default);
          }

          &.lastLine {
            border-bottom: 1px solid transparent;
          }

          &.lastAndPreviousLine {
            border-bottom: 1px solid var(--theme-attention-default);
          }

          &.selected {
            background-color: var(--surface-alternate-1);

            .columnWithActions .lineActions {
              display: block;
            }
          }

          &.cursor {
            cursor: pointer;
          }

          .columnWithActions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;

            .lineActions {
              display: none;
              margin-left: var(--spacing-3);
            }
          }

          &:hover {
            .columnWithActions .lineActions {
              display: block;
            }
          }
        }
      }
    }
  }
}

.ellipsis {
  @include mixins.ellipsis(100%);
}
