.container {
  overflow: auto hidden;
  display: flex;
  flex-direction: row;
  margin: var(--spacing-2);
  height: 36px;
  align-items: center;
  gap: var(--spacing-2);
  .input {
    margin-left: auto;
    min-width: 150px;
    max-width: 260px;
  }

  .inputIconDark {
    color: var(--text-dark-medium-emphasis);
  }

  :global {
    .ant-checkbox-wrapper {
      flex: none;
    }

    .ant-checkbox-inner,
    .ant-radio-inner {
      background-color: transparent;
    }
  }
}
