@use 'designSystem2022.module' as ds;

$headerHeight: 40;

:export {
  headerHeight: $headerHeight;
}

.container {
  width: 100%;
  height: 100%;
}

.topPanel,
.bottomPanel {
  transition: all 0.15s ease-in-out;
  will-change: width;
}
.allot:global(.split-view-sash-dragging) .topPanel,
.allot:global(.split-view-sash-dragging) .bottomPanel {
  /* disable animation while sash dragging */
  transition: none;
}

.topPanelContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bottomPanel {
  background-color: var(--surface-alternate-1);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.bottomHeader {
  height: $headerHeight + px;
  display: flex;
  flex-direction: row;
  flex: none;
  justify-content: space-between;
  align-items: center;
  color: var(--text-dark-medium-emphasis);
  @include ds.SmallLabel;
  padding: 0 var(--spacing-4);
}

.bottomContent {
  flex: 1 1 0%;
  min-height: 0px;
  padding: var(--spacing-4);
  overflow-y: auto;
}

.toggleAttributes {
  position: absolute !important;
  right: var(--spacing-6);
  bottom: 0;
  background-color: var(--surface-alternate-1);
  border-radius: var(--spacing-1) var(--spacing-1) 0 0;
  overflow: hidden;

  > button {
    border-radius: 0 !important;
  }

  &.flipped {
    > button {
      transform: rotate(180deg);
    }
  }
}
