@use 'mixins';

.logo {
  cursor: pointer;
}

.replayName {
  padding: 0 var(--spacing-6);
  @include mixins.ellipsis('100%');
}

.headerActions {
  display: flex;
  align-items: center;
  gap: var(--spacing-6);
}
