.progressBarContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .progressBarBackground {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    overflow: hidden;
    background: var(--grayscale-smoke-lighten-90);
  }
  .darkProgressBarBackground {
    background: var(--special-opacity-white-10);
  }
  .progressBarProgression {
    height: 100%;
    transition: 0.2s ease;
    z-index: 10;
  }
  .slowProgression {
    transition: 2s ease;
  }

  .primary {
    background: var(--theme-primary-default);
  }
  .success {
    background: var(--theme-success-default);
  }
  .attention {
    background: var(--theme-attention-default);
  }
  .warning {
    background: var(--theme-warning-default);
  }
  .danger {
    background: var(--theme-danger-default);
  }

  .dark {
    &.primary {
      background: var(--theme-primary-dark);
    }
    &.success {
      background: var(--theme-success-dark);
    }
    &.attention {
      background: var(--theme-attention-dark);
    }
    &.warning {
      background: var(--theme-warning-dark);
    }
    &.danger {
      background: var(--theme-danger-dark);
    }
  }
}
