.dropdownContent {
  background-color: white;
  border-radius: 6px;
  box-shadow: var(--shadow-default);
  display: flex;
  flex-direction: column;
}

.activeFilterButton {
  border: 1px solid var(--theme-primary-light) !important;
  box-shadow: 0 0 0 2px var(--theme-primary-soft) !important;
  &:hover {
    border: 1px solid var(--theme-primary-light) !important;
  }
}

.checkboxContainer {
  margin: 0 !important;
  width: 100%;
  user-select: none;
  padding: var(--spacing-2) !important;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: var(--special-hover-light);
  }
}

.checkboxContent {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 160px;
  text-transform: capitalize;
}

.dark {
  background-color: var(--surface-alternate-1);

  .checkboxContent {
    color: var(--text-dark-high-emphasis);
  }

  :global {
    .ant-checkbox-inner,
    .ant-radio-inner {
      background-color: transparent;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: var(--theme-primary-default);
      }
    }
  }
}

.overlay {
  max-height: 300px;
  overflow-y: auto;
}
