@use 'designSystem2022.module' as ds;

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .leftPanel,
  .rightPanel {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 auto;
    width: 0;
  }

  .leftPanel {
    .searchInput {
      margin-bottom: var(--spacing-3);
      flex: 0 0 auto;
    }

    .noResult,
    .availableDependenciesList {
      flex: 1 1 auto;
    }

    .noResult {
      white-space: normal;
    }

    .availableDependenciesList {
      padding-top: var(--spacing-3);
      overflow-y: auto;
      flex: 1 1 auto;

      .availableDependencyContent {
        width: 100%;
        margin-bottom: var(--spacing-4);
        justify-content: space-between;
      }
    }
  }

  .dependenciesTitle {
    @include ds.SubtitlesSubtitle-1;
    padding: var(--spacing-2) 0 var(--spacing-6) 0;
    color: var(--text-light-high-emphasis);
    overflow-x: hidden;
    text-overflow: ellipsis;
    flex: 0 0 auto;

    &.dark {
      color: var(--text-dark-high-emphasis);
    }
  }

  .rightPanel {
    .selectedDependenciesList {
      overflow-y: auto;
      padding: 0 var(--spacing-2) var(--spacing-2);
      flex: 1 1 auto;

      .selectedDependency {
        display: flex;
        width: 100%;
        margin: var(--spacing-1) 0;
        align-items: flex-start;

        .selectedDependencyBullet {
          flex: 0 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: var(--spacing-6);
          padding-top: 6px;

          .badge {
            margin-bottom: var(--spacing-2);
          }

          .tail {
            width: 2px;
            height: 20px;
            background-color: var(--grayscale-smoke-lighten-80);

            &.dark {
              background-color: var(--grayscale-smoke-darken-60);
            }
          }
        }

        .selectedDependencyContent {
          justify-content: space-between;
          flex: 1 1 auto;
          overflow-x: auto;
        }

        &:last-child {
          .selectedDependencyBullet {
            .tail {
              display: none;
            }
          }
        }
      }
    }
  }

  .divider {
    height: 100%;
    margin: 0 var(--spacing-4);
    flex: 0 0 auto;
  }

  .dependencyContent {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);

    .dependencyInfo {
      @include ds.BodyBody-2Medium;
      color: var(--text-light-high-emphasis);
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 36px;
      vertical-align: middle;
      white-space: nowrap;
      flex: 1 1 auto;

      &.dark {
        color: var(--text-dark-high-emphasis);
      }
    }

    .dependencyButtons {
      display: flex;
      gap: var(--spacing-1);
    }
  }
}
