.sessionScrollButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
  position: absolute !important;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -150%);
  background-color: var(--surface-alternate-1) !important;
  border: 1px solid var(--grayscale-smoke-darken-60);
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.8));
  transition: 0.3s ease;
  color: var(--text-dark-high-emphasis);
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: var(--spacing-2) var(--spacing-4);
  border-radius: 100px;
  cursor: pointer;

  &:hover {
    background-color: var(--surface-alternate-2) !important;
  }

  &.scrollUp {
    top: 0;
    transform: translate(-50%, 150%);
  }
}
