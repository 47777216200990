@use 'designSystem2022.module' as ds;

:global(.ant-card).card {
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  border-radius: 4px;
  box-shadow: var(--shadows-card);
  background-color: var(--surface-standard-1);
  border: none;

  :global(.ant-card-body) {
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    .title {
      @include ds.CaptionDefault;
      color: var(--text-light-medium-emphasis);
      text-align: center;
    }

    .avatars {
      margin: 16px;
      position: relative;
      height: 64px;

      .leftAvatar {
        position: absolute;
        left: 20px;
        top: 5px;
      }
      .middleAvatar {
        position: absolute;
        z-index: 100;
        left: 68px;
        border: 3px solid var(--surface-standard-1);
        border-radius: 32px;
      }
      .rightAvatar {
        position: absolute;
        left: 124px;
        top: 5px;
      }
    }
  }
}
