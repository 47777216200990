@use 'designSystem2022.module' as ds;

.line {
  position: relative;
  @include ds.SpecialCodeSmall;
  font-weight: 450;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  gap: var(--spacing-2);
}

.attrContainer {
  flex: 1 1 0%;
  overflow-x: auto;
  overflow-y: hidden;
  word-wrap: break-word;
  padding: var(--spacing-2);
}

.attributes {
  --obj-left-margin: var(--spacing-2);
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  color: var(--text-dark-high-emphasis);
  background-color: var(--surface-alternate);

  ul,
  ol {
    list-style-type: none;
    margin: 0 0 0 var(--spacing-2);
    padding: 0;
  }

  > li {
    padding-left: var(--spacing-4);
    border-bottom: 1px solid var(--grayscale-smoke-darken-60);
  }
}

.scalar {
  padding: 1px var(--spacing-1);
  border-radius: 4px;
  cursor: pointer;

  transition: 0.2s;

  &.empty {
    cursor: default;
  }

  i {
    transition: opacity 0.2s ease;
    opacity: 0;
    margin-left: var(--spacing-1);
  }

  &:not(.empty):hover {
    color: var(--theme-primary-light);
    background-color: var(--special-opacity-primary);

    i {
      opacity: 1;
    }
  }
}

.bracket {
  margin-left: var(--obj-left-margin);
}
