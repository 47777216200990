@use 'designSystem2022.module' as ds;

:global(.ant-card).card {
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  border-radius: 4px;
  box-shadow: var(--shadows-card);
  background-color: var(--surface-standard-1);
  border: none;

  :global(.ant-card-body) {
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .progress {
      margin-bottom: -60px;

      :global(.ant-progress-text) {
        @include ds.HeadingsH4;
        color: var(--text-light-medium-emphasis);
        top: 40%;
      }
    }

    .title {
      color: var(--text-light-medium-emphasis);
    }

    .description {
      @include ds.CaptionDefault;
      color: var(--text-light-medium-emphasis);
    }
  }
}
