.mainLayout {
  isolation: isolate;
  width: 100%;
  height: 100%;
}

.layout {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.content {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.dark {
  background-color: var(--surface-alternate);
}
