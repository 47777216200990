@use 'designSystem2022.module' as ds;
@use 'mixins';
@use 'runStudioVideoControls.module.scss' as videoControls;

$partMarginPx: 7;
$scrubberHorizontalPaddingPx: 12;
$caretWidth: 4;
$timelineMarkerWidth: 2;
$interactionIconWidthPx: 16;
$interactionLineWidthPx: 2;
:export {
  partMarginPx: $partMarginPx;
  scrubberHorizontalPaddingPx: $scrubberHorizontalPaddingPx;
  caretWidth: $caretWidth;
  interactionIconWidthPx: $interactionIconWidthPx;
  interactionLineWidthPx: $interactionLineWidthPx;
  timelineMarkerWidth: $timelineMarkerWidth;
}
$partHeightPx: 8;
$partHeight: $partHeightPx + px;

.scrubber {
  position: relative;
  flex: 1 1;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  .container {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-x: auto;
    @include mixins.no-scrollbars();

    .partsContainer {
      height: 100%;

      .part {
        cursor: pointer;
        pointer-events: auto;

        position: absolute;
        height: $partHeight;
        top: calc((videoControls.$visualHeightPx + 1 - $partHeightPx) / 2) + px;

        .track {
          margin-right: $partMarginPx + px;
          margin-left: $partMarginPx + px;
          background-color: var(--grayscale-smoke-darken-40);
          border-radius: 1px;
          height: $partHeight;
          transition: background-color 0.2s;
        }

        .trackScope {
          height: $partHeight;
          background-color: var(--theme-primary-default);
          opacity: 0.3;
        }

        &.error {
          .track {
            background-color: var(--theme-danger-light);
          }
        }

        &:hover {
          .track {
            background-color: var(--grayscale-smoke-darken-20);
          }

          &.error {
            .track {
              background-color: var(--theme-danger-default);
            }
          }
        }
      }

      .interaction {
        position: absolute;
        height: videoControls.$visualHeightPx + px;
        width: $interactionIconWidthPx + px;
        bottom: 0;

        .icon {
          @include mixins.unstyled-button();
          cursor: pointer;
          pointer-events: auto;
          margin-top: 4px;
          margin-left: 2px;
          display: flex;
          color: var(--grayscale-smoke-darken-20);
          i {
            // Otherwise rotation won't work
            display: block;
          }
          &.appFlowEventIcon {
            margin-left: auto;
            margin-right: auto;
            &.appFlowStartEventIcon {
              color: var(--secondary-theme-deep-blue-light);
            }
            &.appFlowFailEventIcon {
              color: var(--theme-danger-light);
            }
          }
        }

        .line {
          cursor: pointer;
          pointer-events: auto;

          position: absolute;
          width: $interactionLineWidthPx + px;
          height: 8px;
          background-color: var(--grayscale-smoke-darken-40);
          top: 21px;
          bottom: 0;

          &.appFlowStartEventLine {
            background-color: var(--secondary-theme-deep-blue-light);
          }
          &.appFlowFailEventLine {
            background-color: var(--theme-danger-light);
          }
        }

        .primaryline {
          background-color: var(--theme-primary-light);
        }

        &.error {
          .icon {
            color: var(--theme-danger-default);
          }
          .line {
            background-color: var(--theme-danger-default);
          }
        }
      }

      .primaryInteraction {
        color: var(--theme-primary-light) !important;
      }
    }
  }

  .caret {
    position: absolute;
    background-color: var(--theme-attention-default);
    width: $caretWidth + px;
    top: 0;
    bottom: 0;
    pointer-events: none;
  }

  .marker {
    @extend .caret;
    margin-top: 13px;
    background-color: var(--special-branch-light-foreground);
    width: $timelineMarkerWidth + px;
    height: 22px;

    &.selected {
      background-color: var(--grayscale-smoke-darken-60);
    }
    &.hovered {
      background-color: var(--theme-primary-default);
    }
  }

  .scrubbableArea {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
  }
}

.scrubber.videoDisabled {
  .container .partsContainer {
    .part {
      cursor: default;
    }

    .interaction .line {
      cursor: default;
    }
  }

  .scrubbableArea {
    cursor: default;
  }
}

:global {
  .tippy-box[data-theme~='run-studio-scrubber'] {
    background-color: var(--surface-alternate);
    color: var(--text-dark-medium-emphasis);
    border-radius: 4px;
    padding: 0;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.4));

    .tippy-content {
      padding: var(--spacing-1) var(--spacing-3);
    }

    &[data-placement^='top'] > .tippy-arrow::before {
      border-top-color: var(--surface-alternate);
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.4));
    }
    &[data-placement^='bottom'] > .tippy-arrow::before {
      border-bottom-color: var(--surface-alternate);
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.4));
    }
    &[data-placement^='left'] > .tippy-arrow::before {
      border-left-color: var(--surface-alternate);
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.4));
    }
    &[data-placement^='right'] > .tippy-arrow::before {
      border-right-color: var(--surface-alternate);
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.4));
    }
  }
}
