.diff {
  white-space: pre-wrap;
  text-align: left;

  .removed {
    color: var(--theme-danger-dark);
    background-color: var(--theme-danger-soft);
    text-decoration: line-through;
  }

  .added {
    color: var(--theme-success-dark);
    background-color: var(--theme-success-soft);
  }
}
