@use 'designSystem/header/header.module.scss' as header;
@use 'containers/layoutWithTabs/layoutWithTabs.module.scss' as headerWithTabs;

.header {
  &:not(.emptyState) {
    padding-top: 14px !important;
    height: headerWithTabs.$headerHeight !important;
  }

  &.emptyState {
    height: header.$headerHeight !important;
  }
}

.headerTitle {
  width: 100%;
}
