.limitReached {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 84px 0 0;

  > *:first-child {
    width: 72px;
    height: 72px;
    margin-bottom: var(--spacing-8);
  }

  > h6 {
    margin-bottom: var(--spacing-4);
  }

  > p {
    text-align: center;
    white-space: pre-line;
  }

  :global(.orange) {
    color: var(--theme-warning-default);
  }
}
