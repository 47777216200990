@use 'mixins';

.block {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--special-pure-white);
  color: var(--text-light-medium-emphasis);
  font-size: var(--font-size-medium);
  transition:
    0.3s ease,
    background 0s;
  & strong {
    color: var(--text-light-high-emphasis);
  }

  .header {
    padding: 16px 25px;
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--font-size-medium);
    padding-block-start: 24px;
    padding-block-end: 13px;
    color: var(--text-light-low-emphasis);
    & p {
      margin-block-end: 11px;
    }
    & strong {
      font-size: var(--font-size-big);
      color: var(--text-light-high-emphasis);
    }
  }
  .itemsScrollable {
    margin: 0 8px;
    overflow-x: auto;
    @include mixins.no-scrollbars();
  }

  .itemsContainer {
    display: flex;
    flex-direction: row;
    padding: var(--spacing-2);

    .itemWrapper {
      display: flex;
      min-width: var(--spacing-9);
      flex-direction: column;
      align-items: center;
      margin-top: var(--spacing-2);

      .item {
        display: flex;
        border-radius: 4px;
        height: 20px;
        min-height: 20px;
        width: 20px;
        min-width: 20px;
        overflow: hidden;
        user-select: none;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 0.8);
        :hover {
          color: rgba(255, 255, 255, 0.8);
        }

        &.successItem {
          background-color: var(--theme-success-default);
          :hover {
            background-color: var(--theme-success-dark);
          }
        }

        &.failedItem {
          background-color: var(--theme-danger-default);
          :hover {
            background-color: var(--theme-danger-dark);
          }
        }

        &.flakyItem {
          background-color: var(--theme-warning-default);
          :hover {
            background-color: var(--theme-warning-dark);
          }
        }

        &.indeterminateItem {
          background-color: #ffc107;
        }
      }

      .latest {
        color: var(--theme-pearl-default);
        font-size: var(--font-size-small);
      }
    }
  }
}

.popoverOverlay {
  min-width: 200px;
  max-width: 300px;
  :global(.ant-popover-inner) {
    box-shadow:
      0px 0px 1px rgba(12, 26, 75, 0.24),
      0px 3px 8px rgba(50, 50, 71, 0.05);
  }
  :global(.ant-popover-inner-content) {
    padding: 0px;
    font-size: var(--font-size-medium);
    color: var(--text-light-medium-emphasis);
  }

  .popoverInfo {
    padding: var(--spacing-3);
    overflow: hidden;

    .popoverInfoHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      gap: var(--spacing-4);
      min-height: 22px;
    }

    .commitMessage {
      color: var(--grayscale-midnight-default);
      font-weight: 500;
      margin-block-start: var(--spacing-2);
      white-space: pre-wrap;
      // Let line breaks appear as such
      overflow-wrap: break-word;
      // break even inside long words (like branch names)
      line-clamp: 6;
      -webkit-line-clamp: 6;
      // This is webkit prefixed but even Firefox support it ¯\_(ツ)_/¯
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }

    .gitSha {
      font-size: 11px;
      margin-block-start: var(--spacing-3);
      font-family: monospace;
    }
  }

  .popoverStatus {
    padding: var(--spacing-2) var(--spacing-3);
    border-block-start: 1px solid #edf2f7;

    &.successStatus {
      color: var(--theme-success-default);
    }

    &.failedStatus {
      color: var(--theme-danger-default);
    }

    &.flakyStatus {
      color: var(--theme-warning-default);
    }

    .popoverStatusIcon {
      margin-inline-end: var(--spacing-1);
    }
  }
}
