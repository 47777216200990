@use 'designSystem2022.module' as ds;

.container {
  width: 100%;
  height: 100%;
  padding: 0 var(--spacing-4) var(--spacing-2);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.analyticsTable {
  flex: 1;
  border: solid 1px var(--grayscale-smoke-darken-60);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  .analyticsHeaders {
    @include ds.BodyBody-2Medium;
    background-color: var(--surface-alternate-2);
    color: var(--text-dark-medium-emphasis);
    dl {
      width: 100%;
      min-height: 36px;
      height: 36px;
      align-items: center;
    }
  }

  dl {
    height: 40px;
    display: grid;
    grid-template-columns: 50px 150px 1fr 1fr 150px;
    text-align: left;
    border-top: 1px solid var(--grayscale-smoke-darken-60);
    margin: 0;

    dt {
      @include ds.BodyBody-1Regular;
      color: var(--text-dark-high-emphasis);
      align-self: center;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .logo {
        width: 16px;
        margin-left: var(--spacing-4);
      }
    }

    &.analyticItem {
      cursor: pointer;
    }
    &.selected {
      background-color: var(--surface-alternate-1);
    }
  }

  .analyticsBody {
    flex: 1;
    overflow-y: auto;
    color-scheme: dark;
  }
}
