/***************************************************************************
 * SASS Mixins independent of our design system.
 ***************************************************************************/

/** Limit the size of an element by ellipsing the text content with '...' if too long. */
@mixin ellipsis($maxWidth) {
  max-width: $maxWidth;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/** Hide the scrollbars of a scrollable element */
@mixin no-scrollbars() {
  scrollbar-width: none; // FireFox

  -ms-overflow-style: none; // IE/Edge

  &::-webkit-scrollbar {
    // Chrome/Safari
    display: none;
    height: 0;
    background: transparent;
  }
}

/** Remove the default style from a <button /> */
@mixin unstyled-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: inherit;
  outline: inherit;
  text-align: inherit;
}
