@use 'designSystem2022.module' as ds;
@use 'runStudioVideoControls.module.scss' as videoControls;

:export {
  horizontalMarginPx: calc(ds.$spacing-8Px * 2);
  verticalMarginPx: calc(ds.$spacing-8Px * 2 + videoControls.$visualHeightPx);
  gapPx: ds.$spacing-3Px;
}

.container {
  background-color: var(--surface-alternate);
  padding: 0 var(--spacing-8);
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: var(--spacing-3);

  width: 30%;
  width: max(30%, 300px);
  min-width: 30%;
  min-width: max(30%, 300px);

  video {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
  }

  &.loading video {
    height: 0;
  }
}
