i.statusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: var(--special-pure-white);

  &.danger {
    background-color: var(--theme-danger-soft);
    color: var(--theme-danger-dark);

    &.dark {
      background-color: var(--special-opacity-danger);
      color: var(--theme-danger-light);
    }
  }

  &.warning {
    background-color: var(--theme-warning-soft);
    color: var(--theme-warning-dark);

    &.dark {
      background-color: var(--special-opacity-warning);
      color: var(--theme-warning-light);
    }
  }

  &.attention {
    background-color: var(--theme-attention-soft);
    color: var(--theme-attention-dark);

    &.dark {
      background-color: var(--special-opacity-attention);
      color: var(--theme-attention-light);
    }
  }

  &.success {
    background-color: var(--theme-success-soft);
    color: var(--theme-success-dark);

    &.dark {
      background-color: var(--special-opacity-success);
      color: var(--theme-success-light);
    }
  }

  &.default {
    background-color: var(--grayscale-smoke-lighten-80);
    color: var(--grayscale-smoke-default);

    &.dark {
      background-color: var(--special-opacity-smoke);
      color: var(--grayscale-smoke-lighten-20);
    }
  }
}
