.filterBar {
  display: flex;
  align-items: center;
  padding: var(--spacing-2) var(--spacing-4);
  gap: var(--spacing-4);

  .level {
    flex: 0 1 100px;
    & > * {
      width: 100%;
    }
  }

  .dropdown {
    height: 30px;
    background-color: var(--surface-alternate-1) !important;
  }

  .empty {
    flex: 1;
  }

  .search {
    max-width: 260px;
  }
}
