@use 'designSystem2022.module' as ds;

$inner-card-select-width: 296px;

.scrollableContent {
  width: 100%;
  overflow-y: auto;
  height: 100%;

  .pricingContent {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    color: var(--text-light-high-emphasis);

    .pricingHeader {
      @include ds.HeadingsH2;
      margin-top: 120px;
      margin-bottom: 128px;
      white-space: break-spaces;
      text-align: center;

      .primary {
        color: var(--theme-primary-default);
      }
    }

    .subtitle {
      @include ds.HeadingsH3;
      margin-bottom: 72px;
    }

    .switchContainer {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-bottom: 80px;
      user-select: none;

      span {
        @include ds.BodyBody-1Medium;
        color: var(--text-light-medium-emphasis);
      }
    }

    .cardsContainer {
      display: flex;
      gap: 24px;
      margin-bottom: 120px;

      .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 560px;
        height: 600px;
        border-radius: 4px;
        padding: 32px;
        box-shadow: var(--shadows-card-hover);
        @media (max-width: 1200px) {
          width: 400px;
        }

        .cardHeader {
          @include ds.HeadingsH5;
          margin-bottom: 16px;
        }

        .cardDescription {
          @include ds.BodyBody-1Paragraph;
          color: var(--text-light-medium-emphasis);
          margin-bottom: 48px;
        }

        .pricingBox {
          width: $inner-card-select-width;
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 12px;
          border-radius: 4px;
          border: 1px solid var(--grayscale-smoke-lighten-80);
          margin-bottom: 40px;

          .customPricingHeader {
            @include ds.HeadingsH5;
            margin-bottom: 0px;
          }

          .pricingDescription {
            @include ds.BodyBody-2Regular;
            color: var(--text-light-medium-emphasis);
          }
        }

        .teamPlanPrice {
          @include ds.HeadingsH5;
          width: $inner-card-select-width;
          display: flex;
          align-items: center;
          gap: 4px;

          .subtitles2 {
            @include ds.SubtitlesSubtitle-2;
            color: var(--text-light-low-emphasis);
          }
        }

        .bulletList {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .bulletFeature {
            @include ds.BodyBody-1Regular;
            display: flex;
            gap: 12px;
          }
        }

        .bottomButtons {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }
    }

    table {
      width: 100%;
      margin-bottom: 40px;
      thead {
        border-bottom: 1px solid var(--grayscale-smoke-lighten-80);

        th {
          @include ds.HeadingsH6;
        }
        th:first-child {
          @include ds.HeadingsH6;
        }
      }
      th {
        @include ds.SubtitlesSubtitle-1;
        width: 250px;
        padding: 20px 0;
      }
      th:first-child {
        width: max-content !important;
        text-align: left;
        @include ds.BodyBody-1Regular;
      }
    }

    .bottompadding {
      width: 100%;
      min-height: 100px;
    }
  }
}

.featureBadgeActive {
  color: var(--theme-panam-blue-dark);
}
.featureBadgeInactive {
  color: var(--grayscale-smoke-lighten-60);
}
