@use 'designSystem2022.module' as ds;
@use 'mixins';

.onboardingViewMain {
  --onboarding-content-width: auto;

  h4 {
    margin-bottom: var(--spacing-8);
  }

  .onboardingJoinExistingOrganization {
    display: flex;
    flex-direction: column;

    .column,
    .newOrgButtonContainer {
      min-width: 312px;
    }

    .card {
      border-radius: 6px;
      border: none;
      box-shadow: var(--shadows-card);
      background-color: var(--surface-standard-1);
      padding: 16px;

      :global(.ant-card-head),
      :global(.ant-card-body),
      :global(.ant-card-head-title),
      :global(.ant-card-extra) {
        border: none;
        padding: 0px;
      }

      :global(.ant-card-head-title) {
        color: var(--text-light-high-emphasis);
        @include ds.SubtitlesSubtitle-1;

        display: flex;
        align-items: center;
        gap: var(--spacing-2);

        > span:first-child {
          text-overflow: ellipsis;
          overflow: hidden;
        }
        padding-right: var(--spacing-2);
      }

      .ownerLabel {
        color: var(--text-light-low-emphasis);
        @include ds.CaptionDefault;
        margin-bottom: 4px;
      }

      .ownerEmail {
        color: var(--text-light-high-emphasis);
        @include ds.BodyBody-2Paragraph;
        @include mixins.ellipsis(100%);
        margin-bottom: 18px;
      }

      .organizationDetails {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        gap: 24px;

        .info {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: nowrap;
          gap: 8px;
          color: var(--text-light-medium-emphasis);
        }

        .iconLabel {
          @include ds.BodyBody-2Medium;
          color: var(--text-light-medium-emphasis);
        }
      }
    }

    .newOrgButtonContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      min-height: 150px;
    }
  }
}
