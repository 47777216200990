.content {
  display: flex;
  align-items: center;
  gap: var(--spacing-3);

  .modelOS {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
  }
}

.flag {
  width: 20px;
  height: 16px;
  border-radius: 2px;
  overflow: hidden;
}

.flagEmoji {
  color: var(--text-light-high-emphasis);
}
