@use 'designSystem2022.module' as ds;

.checkEmail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .image {
    margin-bottom: var(--spacing-8);
  }

  .description {
    @include ds.BodyBody-1Paragraph;
    color: var(--text-light-medium-emphasis);
    margin-bottom: var(--spacing-6);
    margin-top: var(--spacing-3);
    max-width: 404px;
    text-align: center;
  }

  .formRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .buttons {
    margin-top: var(--spacing-6);
    display: flex;
    justify-content: flex-end;
  }
}
