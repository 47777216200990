.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.shareContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  margin-bottom: 6px;
}
.publicShareContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
}
.shareInfo {
  display: flex;
  align-items: center;
}
.shareTextContainer {
  display: inline-block;
  justify-content: space-between;
  margin-right: 6px;
  margin-left: 6px;
}
.shareTitle {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}
.shareDetails {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--text-dark-medium-emphasis);
}
.shareButton {
  float: right;
  margin-top: 10px;
}
.shareLinkInput {
  cursor: text !important;
}
