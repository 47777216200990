@use 'designSystem2022.module' as ds;

.previewResponseTabs {
  flex: 1 1 0;
  height: 0;

  :global {
    .ant-tabs-nav {
      flex: 0 0 auto;
    }
    .ant-tabs-content-holder {
      flex: 1 1 0px;
      height: 0px;
      overflow-y: auto;
      .ant-tabs-content {
        height: 100%;
        .ant-tabs-tabpane {
          height: 100%;
        }
      }
    }
  }

  .responseBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    .statusRow {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: var(--spacing-2);
      flex: 0 0 auto;
      padding-top: var(--spacing-1);
      padding-bottom: var(--spacing-4);
      color: var(--text-dark-medium-emphasis);
      @include ds.ButtonDefault;
    }

    .jsonViewer {
      flex: 1 1 0;
      height: 0;
      overflow: auto;
      width: 100%;
    }

    .outputVariablesWrapper {
      flex: 0 0 auto;
      max-height: 50%;
      overflow-y: auto;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: var(--spacing-3);
      gap: var(--spacing-2);

      .outputBanner {
        border: 1px solid var(--grayscale-smoke-default);
      }

      .outputVariablesHeaders {
        display: flex;
        align-items: center;
        width: 100%;
        padding-right: 44px;
        gap: var(--spacing-2);
        padding-top: var(--spacing-2);
        .outputVariablesHeader {
          flex: 1 1 auto;
          width: 0;
          @include ds.CaptionDefault;
          color: var(--text-dark-high-emphasis);
        }
      }
    }
  }

  .headersTable {
    border-right: 1px solid var(--grayscale-smoke-darken-60);
    border-left: 1px solid var(--grayscale-smoke-darken-60);
    border-radius: 4px;
    .tableRow {
      height: 48px;
      border-bottom: 1px solid var(--grayscale-smoke-darken-60);
      &:last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &.tableHeaders {
        color: var(--text-dark-medium-emphasis);
        @include ds.BodyBody-2Medium;
        background-color: var(--grayscale-smoke-darken-60);
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
      &.tableContentRow {
        color: var(--text-dark-high-emphasis);
        @include ds.BodyBody-1Regular;
      }
      .cell {
        overflow-x: hidden;
        text-overflow: ellipsis;
        padding: var(--spacing-3);
        margin: auto;
      }
    }
  }
}

.outputVariable {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--spacing-2);

  input {
    flex-grow: 1;
  }
}
