@use 'designSystem2022';
@use 'antdStyling';

@import 'assets/fonts/waldo/waldo-icon-font.scss';
@import 'assets/fonts/Inter/inter-font.scss';
@import 'assets/fonts/FiraCode/fira-code-font.scss';
@import 'assets/fonts/FontAwesome/fontAwesome.scss';

@import url('bootstrap-icons/font/bootstrap-icons.css');
@import url('allotment/dist/style.css');
@import url('tippy.js/dist/tippy.css');
@import url('tippy.js/dist/border.css');
@import url('mapbox-gl/dist/mapbox-gl.css');

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--default-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8fa;
  letter-spacing: -0.2px;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior-x: none;
}

svg {
  pointer-events: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--code-font-family);
}

img {
  max-width: 100%;
}

b,
strong {
  font-weight: 500;
}

.no-cursor {
  cursor: none;
}

.no-drag {
  pointer-events: none;
}

.cursor-x {
  cursor: ew-resize;
}

.rotate45 {
  transform: rotateZ(45deg);
}

.vertical-center-modal {
  text-align: center;
  white-space: nowrap;
}

.vertical-center-modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
  max-width: none;
  padding-bottom: 0;
}

[draggable='true'] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}

/* Overrides for folder-tree-select */
.folder-tree-select {
  max-height: 300px;
  overflow-y: auto;
}
.folder-tree-select .ant-tree-treenode {
  padding-left: 0px;
  padding-bottom: 0;
  padding-top: 4px;
  height: 32px;
  bottom: 0;
}
.folder-tree-select .ant-tree-treenode:before {
  height: 100%;
}
.folder-tree-select .ant-tree-treenode-selected:before {
  background-color: rgba(232, 233, 236, 0.5) !important;
}
.folder-tree-select .ant-tree-checkbox-inner {
  border-radius: 2px;
}

.flow-archive-notification {
  width: 300px;
  padding: 16px 36px 16px 16px;
  border-radius: 6px;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 0 0 rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(0, 0, 0, 0.03);
}
.flow-archive-notification {
  width: 240px;
}
.flow-archive-notification .ant-notification-notice-message {
  display: block;
  margin: 0;
  padding: 0;
  line-height: inherit;
}
.flow-archive-notification .ant-notification-notice-message a {
  font-weight: 500;
  color: #6c75f1 !important;
}

.flow-archive-notification .ant-notification-notice-close {
  padding: 10px;
  right: 0;
  top: 6px;
}

.flow-archive-notification .ant-notification-notice-description {
  display: none;
}
