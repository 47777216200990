@use 'designSystem2022.module' as ds;

.processingContainer {
  display: flex;
  justify-content: flex-end;
}

.processingTooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-6);
  @include ds.BodyBody-2Medium;
  color: var(--text-light-medium-emphasis);
}

.processingOverlay {
  padding: var(--spacing-2) 0;
  :global {
    .ant-popover-inner {
      box-shadow: var(--shadows-default);
    }
    .ant-popover-inner-content {
      padding: var(--spacing-4);
    }
  }
}

.row {
  cursor: pointer;
}

.build {
  max-width: 140px !important;
}

.device {
  color: var(--text-light-low-emphasis);

  .modelAndOs {
    color: var(--text-light-high-emphasis);
  }
}
