@use 'designSystem2022.module' as ds;

.sliderContainer {
  margin-top: var(--spacing-4);
  padding-left: var(--spacing-8);
  padding-right: var(--spacing-8);

  :global(.ant-slider-with-marks) {
    margin-bottom: 0;
    padding-bottom: var(--spacing-10);
  }

  :global(.ant-slider-mark-text) {
    @include ds.SmallLabel;
    color: var(--text-light-medium-emphasis);
    min-width: max-content;
    white-space: break-spaces;
    top: var(--spacing-1);
  }
}

.banner {
  margin-top: var(--spacing-6);
}
