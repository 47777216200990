.fullScreenView {
  background-color: white;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;

  & > header {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    box-shadow: var(--shadows-default);
    padding: 16px 24px;

    .logo {
      height: 36px;
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 32px;
    }
  }

  & > main {
    flex: 1 1 0;
    overflow: auto;
    display: flex;

    &.centered {
      flex-direction: column;
      align-items: center;
      padding: var(--spacing-4) 0;

      // justify-content: center here would break the scrolling
      > *:first-child {
        margin-top: auto;
      }

      > *:last-child {
        margin-bottom: auto;
      }
    }
  }
}
