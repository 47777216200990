@use 'designSystem2022.module' as ds;

.deviceCascader {
  width: 100%;
  background-color: #fff;

  :global(.ant-cascader) {
    width: 100%;
  }
}

.banner {
  margin-top: 26px;
}
