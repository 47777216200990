.boxWrapper {
  height: 100%;
  width: 100%;
  min-width: 300px;
  padding: var(--spacing-6);
  margin: 0 auto;
}

.box {
  width: 100%;
  border-radius: var(--spacing-1);
  background: white;
  box-shadow: var(--shadows-default);
  position: relative;
  padding: var(--spacing-4);
}
.box > * {
  margin-bottom: 30px;
}

.orgContainer {
  min-width: 300px;
  max-width: 656px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
