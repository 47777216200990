.menu {
  padding: var(--spacing-2);
  display: none;
  flex-flow: column nowrap;
  gap: var(--spacing-1);
  background-color: white;
  border-radius: 6px;
  min-width: 160px;
  box-shadow: var(--shadows-card-hover);
  z-index: 5;

  &.dark {
    background-color: var(--surface-alternate-1);
  }

  &[data-show] {
    display: flex;
  }

  & > a,
  & > button,
  & > a > button {
    justify-content: left;
    height: 32px;
  }

  & > a > button {
    width: 100%;
  }

  i {
    width: 15px;
  }
}

.mediumEmphasis {
  color: var(--text-light-medium-emphasis) !important;

  &.dark {
    color: var(--text-dark-medium-emphasis) !important;
  }
}
