@use 'designSystem2022.module' as ds;

:global(.ant-layout).layout {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: var(--surface-standard-1);

  .content {
    width: 100%;
    overflow: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    > *:first-child {
      margin-top: auto;
    }

    > *:last-child {
      margin-bottom: auto;
    }
  }
}
