/***************************************************************************
 * CSS variables for our design system
 ***************************************************************************/

@use 'designSystem2022.module' as *;

:root {
  /********************************************************************
   * Fonts
   *******************************************************************/

  --default-font-family: Inter, Verdana, sans-serif;
  --code-font-family: 'Fira Code', Consolas, Menlo, Courier, monospace;

  /********************************************************************
   * Color palette
   *******************************************************************/
  --surface-standard-0: #fcfcfc;
  --surface-standard-1: #fff;
  --theme-primary-default: #6c75f1;
  --theme-primary-light: #a2a4ff;
  --surface-alternate: #1a1d1f;
  --theme-primary-soft: #ebebff;
  --surface-alternate-2: #33383f;
  --surface-standard-2: #f9fafe;
  --surface-alternate-1: #272b30;
  --theme-primary-dark: #5a5de2;
  --theme-panam-blue-default: #3a4f80;
  --theme-panam-blue-dark: #032753;
  --theme-panam-blue-soft: #dee4f2;
  --theme-panam-blue-light: #8795c0;
  --theme-pearl-default: #9f98bd;
  --theme-pearl-dark: #6f698d;
  --theme-pearl-light: #afa3d7;
  --theme-pearl-soft: #ececf8;
  --theme-attention-soft: #fff2cc;
  --theme-attention-default: #fec600;
  --theme-attention-light: #ffd44c;
  --theme-success-dark: #008251;
  --theme-attention-dark: #b08d0c;
  --theme-warning-default: #fd800c;
  --theme-success-light: #65c898;
  --theme-success-default: #34b37d;
  --theme-success-soft: #d9f2e5;
  --theme-danger-light: #dd5246;
  --theme-warning-dark: #c35100;
  --theme-warning-light: #fea552;
  --theme-danger-soft: #fbdddb;
  --theme-warning-soft: #ffe7d1;
  --theme-danger-default: #cc321d;
  --secondary-theme-deep-blue-default: #2067ed;
  --secondary-theme-deep-blue-dark: #1048b1;
  --theme-danger-dark: #be1809;
  --secondary-theme-pink-default: #f06292;
  --secondary-theme-deep-blue-soft: #dce8fe;
  --secondary-theme-deep-blue-light: #6699fa;
  --secondary-theme-pink-dark: #ba2d65;
  --secondary-theme-pink-soft: #ffe0ee;
  --secondary-theme-pink-light: #f881b4;
  --secondary-theme-pine-green-default: #5a7c3e;
  --secondary-theme-pine-green-soft: #e2ebdb;
  --secondary-theme-pine-green-dark: #2e5014;
  --secondary-theme-deep-purple-soft: #ece1f4;
  --secondary-theme-deep-purple-default: #541685;
  --secondary-theme-deep-purple-dark: #230057;
  --secondary-theme-deep-purple-light: #a16fc8;
  --secondary-theme-deep-cyan-default: #336d8c;
  --secondary-theme-deep-cyan-soft: #dfeaf1;
  --secondary-theme-deep-cyan-light: #74a5c3;
  --grayscale-midnight-lighten-90: #f7f7f7;
  --grayscale-midnight-lighten-60: #adadad;
  --grayscale-midnight-lighten-20: #5b5b5b;
  --grayscale-midnight-lighten-80: #d6d6d6;
  --grayscale-midnight-lighten-40: #848484;
  --secondary-theme-pine-green-light: #88ab6a;
  --grayscale-midnight-darken-40: #1e1e1e;
  --grayscale-midnight-darken-60: #141414;
  --grayscale-midnight-darken-80: #0a0a0a;
  --secondary-theme-deep-cyan-dark: #00425f;
  --grayscale-smoke-lighten-80: #e8e9ec;
  --grayscale-smoke-lighten-90: #f3f4f5;
  --grayscale-smoke-lighten-60: #d2d4d9;
  --grayscale-smoke-default: #9095a2;
  --grayscale-smoke-lighten-20: #a6aab4;
  --grayscale-smoke-darken-20: #6f7585;
  --grayscale-midnight-darken-20: #282828;
  --grayscale-smoke-darken-40: #535863;
  --grayscale-smoke-darken-60: #373a42;
  --grayscale-smoke-darken-80: #1b1d21;
  --text-light-high-emphasis: rgba(0, 0, 0, 0.87);
  --text-light-medium-emphasis: rgba(0, 0, 0, 0.6);
  --text-light-low-emphasis: rgba(0, 0, 0, 0.38);
  --text-dark-high-emphasis: rgba(255, 255, 255, 0.9);
  --text-dark-medium-emphasis: rgba(255, 255, 255, 0.7);
  --text-dark-low-emphasis: rgba(255, 255, 255, 0.48);
  --special-pure-black: #000;
  --grayscale-smoke-lighten-40: #bcbfc7;
  --grayscale-midnight-default: #333;
  --special-hover-dark: rgba(255, 255, 255, 0.1);
  --special-hover-primary: rgba(108, 117, 241, 0.06);
  --special-hover-primary-dark: rgba(108, 117, 241, 0.15);
  --special-pure-white: #fff;
  --special-backdrop-default: rgba(0, 0, 0, 0.25);
  --special-branch-dark-background: rgba(56, 139, 253, 0.15);
  --special-hover-light: rgba(0, 0, 0, 0.05);
  --special-branch-dark-foreground: #58a6ff;
  --special-opacity-primary: rgba(108, 117, 241, 0.08);
  --special-branch-light-foreground: #0969da;
  --special-opacity-success: rgba(52, 179, 125, 0.08);
  --special-branch-light-background: #ddf4ff;
  --special-opacity-smoke: rgba(144, 149, 162, 0.13);
  --special-opacity-panam-blue: rgba(58, 79, 128, 0.22);
  --special-opacity-warning: rgba(253, 128, 12, 0.08);
  --special-opacity-pearl: rgba(152, 157, 189, 0.1);
  --special-opacity-danger: rgba(204, 50, 29, 0.08);
  --special-opacity-deep-purple: rgba(119, 31, 188, 0.12);
  --special-opacity-deep-cyan: rgba(51, 109, 140, 0.1);
  --special-opacity-attention: rgba(254, 198, 0, 0.05);
  --special-opacity-white-15: rgba(255, 255, 255, 0.15);
  --special-opacity-pink: rgba(240, 98, 146, 0.1);
  --special-opacity-white-5: rgba(255, 255, 255, 0.05);
  --special-opacity-pine-green: rgba(90, 124, 62, 0.1);
  --special-opacity-deep-blue: rgba(32, 103, 237, 0.1);
  --special-opacity-white-10: rgba(255, 255, 255, 0.1);

  /********************************************************************
   * Spacing
   *******************************************************************/
  --spacing-1: #{$spacing-1Px + px}; /* 4px */
  --spacing-2: #{$spacing-2Px + px}; /* 8px */
  --spacing-3: #{$spacing-3Px + px}; /* 12px */
  --spacing-4: #{$spacing-4Px + px}; /* 16px */
  --spacing-5: #{$spacing-5Px + px}; /* 20px */
  --spacing-6: #{$spacing-6Px + px}; /* 24px */
  --spacing-8: #{$spacing-8Px + px}; /* 32px */
  --spacing-9: #{$spacing-9Px + px}; /* 36px */
  --spacing-10: #{$spacing-10Px + px}; /* 40px */
  --spacing-12: #{$spacing-12Px + px}; /* 48px */
  --spacing-14: #{$spacing-14Px + px}; /* 56px */
  --spacing-16: #{$spacing-16Px + px}; /* 64px */

  /********************************************************************
   * Font size
   *******************************************************************/
  --font-size-tiny: 10px;
  --font-size-small: 12px;
  --font-size-medium: 14px;
  --font-size-big: 16px;
  --font-size-large: 20px;
  --font-size-extra-large: 50px;

  /********************************************************************
   * Effects
   *******************************************************************/

  --shadows-card: 0px 0px 1px rgba(108, 117, 241, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  --shadows-card-hover: 0px 0px 3px rgba(108, 117, 241, 0.14),
    0px 6px 10px -1px rgba(50, 50, 71, 0.11);
  --shadows-tooltip-light: 0px 4px 5px rgba(0, 0, 0, 0.25);
  --shadows-tooltip-dark: 0px 4px 5px rgba(0, 0, 0, 0.4);
  --shadows-default: 0px 0px 1px rgba(108, 117, 241, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  --shadows-hover: 0px 0px 3px rgba(108, 117, 241, 0.14), 0px 6px 10px -1px rgba(50, 50, 71, 0.11);

  /********************************************************************
   * Dimensions
   *******************************************************************/
  --body-min-height: 650px;
  --sidebar-width: 230px;
  --sidebar-mini-width: 48px;
  --dependency-panel-width: 420px;
}
