$deviceInteractorMargin: 24;
$deviceInteractorButtonWidth: 36;
$deviceInteractorButtonSize: $deviceInteractorMargin + $deviceInteractorButtonWidth;

:export {
  deviceInteractorMargin: $deviceInteractorMargin;
  deviceInteractorButtonWidth: $deviceInteractorButtonWidth;
  deviceInteractorButtonSize: $deviceInteractorButtonSize;
}

.devicePanel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.interactionZone {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  user-select: none;
}
