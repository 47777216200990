.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.actionBar {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  width: 100%;
  padding: 0 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 8;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 0%;
  overflow: auto;
  padding: 0 var(--spacing-4);
}

.selected {
  background-color: var(--grayscale-smoke-lighten-90);
}

.column {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.search {
  max-width: 240px;
}

.fixedWidthNoContent {
  width: 340px !important;
}

.timeline {
  width: 100%;
}

.emptyIcon {
  margin-bottom: var(--spacing-6);
}
