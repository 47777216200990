@use 'designSystem2022.module' as ds;

$headerHeight: 110px;

.layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: $headerHeight !important;
    width: 100%;
    z-index: 200;
    padding: 24px 32px 0px 32px !important;
  }

  .content {
    flex: 1;
    overflow: auto;
  }

  .tabs {
    width: 100%;

    :global {
      .ant-tabs-nav {
        margin-bottom: 0 !important;
        &:before {
          border-bottom: none !important;
        }

        .ant-tabs-tab-btn {
          gap: var(--spacing-2);
        }
      }
    }
  }
}
