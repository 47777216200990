.publicReplayContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.notFoundWrapper {
  flex: 1;
  background-color: var(--surface-alternate);
  display: flex;
  justify-content: center;

  > *:first-child {
    gap: var(--spacing-4);
    max-width: 400px;
  }
}
