@use 'designSystem2022.module' as ds;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  .title {
    @include ds.HeadingsH4;
    text-align: center;
  }

  .content {
    @include ds.BodyBody-1Paragraph;
    color: var(--text-light-medium-emphasis);
    white-space: pre-wrap;
    text-align: center;
    max-width: 530px;
  }

  .scheduleContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
