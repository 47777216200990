@use 'designSystem2022.module' as ds;

.sessionSection {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  padding: var(--spacing-4);
  border-top: 1px solid var(--grayscale-smoke-lighten-90);

  .sessionTitle {
    @include ds.CaptionDefault;
    color: var(--text-light-high-emphasis);

    &.dark {
      color: var(--text-dark-medium-emphasis);
    }
  }
}
