.layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  background-color: var(--surface-standard-1) !important;
}

.headerButtons {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-4);
}

.listContainer {
  overflow-y: auto;
  flex: 1;
}

.content {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  padding: 0 var(--spacing-4);
}
