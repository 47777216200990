@use 'designSystem2022.module' as ds;

.info.tag,
.info.icon {
  color: var(--text-dark-medium-emphasis);
  background-color: var(--special-opacity-white-5);
}

.debug {
  color: var(--text-dark-low-emphasis);
  &.tag,
  &.icon {
    background-color: var(--special-opacity-white-5);
  }
}

.warn {
  color: var(--theme-warning-light);
  &.tag,
  &.icon {
    background-color: var(--special-opacity-warning);
  }
}

.error {
  color: var(--theme-danger-light);
  &.tag,
  &.icon {
    background-color: var(--special-opacity-danger);
  }
}

.tag {
  @include ds.CaptionSemi-Bold;
  padding: 4.5px 8px;
  border-radius: 2px;
  margin: auto 0px;
}

.message {
  &:info {
    color: var(--text-dark-high-emphasis);
  }
  flex: 1;
  min-width: 0;
  padding: 0;
  margin: auto 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
