@use 'mixins';
@use 'designSystem2022.module' as ds;

.container {
  width: 100%;
  position: relative;

  &.disabled {
    cursor: not-allowed;
  }

  .input {
    $border-color: var(--grayscale-smoke-darken-40);
    color-scheme: dark;
    height: 38px;
    line-height: 24px !important;
    color: var(--text-dark-high-emphasis);
    @include ds.BodyBody-2Regular;
    border-radius: 4px !important;
    border: 1px solid $border-color;
    padding: 5.75px 12px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    @include mixins.no-scrollbars();

    &.disabled {
      color: var(--text-dark-low-emphasis);
      background-color: var(--grayscale-smoke-darken-60);
    }

    &:empty:not(:focus):before {
      content: attr(data-text);
      color: var(--text-dark-low-emphasis);
      pointer-events: none;
    }

    &:hover:not(.disabled) {
      border: 1px solid var(--theme-primary-default);
    }

    &:focus:not(.disabled) {
      border: 1px solid var(--theme-primary-default);
      box-shadow: 0px 0px 4px rgba(108, 117, 241, 0.7);
    }

    &:focus-visible {
      outline: unset !important;
    }

    .textNode {
      white-space: pre;
      display: inline-block;
      line-height: 24px;
      height: 24px;
    }
  }

  .variableCell {
    display: inline-block !important;
    height: 24px;

    &::before {
      content: '$';
    }
  }

  .dropdown {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--surface-alternate-1);
    box-shadow: var(--shadows-tooltip-light);
    padding: 12;
    border-radius: var(--spacing-2);
    display: none;
    z-index: 10;
    color-scheme: dark;
    min-width: 200px;

    &.noDivider {
      :global(.ant-divider-horizontal) {
        display: none !important;
      }
    }

    &.dropdownVisible {
      display: flex;
    }

    .popMenu {
      background-color: transparent;
      padding: var(--spacing-1) 0;

      .menuItem {
        border-radius: var(--spacing-1);
        padding: var(--spacing-2);
        height: 48px;
        margin: 0;
        color: var(--text-dark-high-emphasis);
        @include ds.BodyBody-2Regular;
        &:hover,
        &.preselected {
          color: var(--text-dark-high-emphasis);
          background-color: var(--special-hover-dark);
        }

        :global(.ant-menu-title-content) {
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 2px;
        }

        .menuItemVariableName {
          flex: 1 1 0;
          line-height: 16px;
        }

        .menuItemVariablePattern {
          flex: 1 1 0;
          line-height: 12px;
          color: var(--text-dark-low-emphasis);
          font-weight: 500;
          font-size: 10px;
          letter-spacing: 0.5px;
        }
      }
    }

    .createVariableButton {
      width: 100%;
      &.focus {
        background-color: var(--special-hover-dark);
      }
    }
  }

  .tooltipTextWrapper {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
