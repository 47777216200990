:global(.ant-list).runs {
  padding: 10px 0;
}

:global(.ant-list-item).listItem {
  padding: 0;

  &:hover {
    background: var(--special-hover-light);
  }

  &.activeListItem {
    background: var(--special-hover-light);
  }
}
