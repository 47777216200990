@use 'designSystem2022.module' as ds;

$visualHeightPx: 48;

.controlsContainer {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  height: $visualHeightPx + px;

  // The controls component full height contains the interaction icons but they visually appear out of it.
  // To make it look like that, the part that look like 'inside' of the component is actually faked by this background
  // and carefull sizing of the components that appear to be inside.
  .fakeBackground {
    z-index: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 48px;
    background-color: var(--surface-alternate);
    border-top: 1px solid var(--grayscale-smoke-darken-60);
  }

  .controls {
    z-index: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    height: $visualHeightPx + px;
    position: relative;

    .playButtonContainer {
      padding: 6px var(--spacing-3);
      height: $visualHeightPx + px;
    }

    .timeBlock {
      @include ds.SubtitlesSubtitle-1;
      height: $visualHeightPx + px;
      display: flex;
      align-items: center;
      margin: 0 var(--spacing-3);
      color: var(--text-dark-high-emphasis);
    }
  }
}
