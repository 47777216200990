@use 'designSystem2022.module' as ds;

.helperPopover {
  width: 375px;
  :global {
    .ant-popover-inner {
      box-shadow: var(--shadows-default);
    }
    .ant-popover-inner-content {
      padding: 0px;
    }
  }
}

.popoverText {
  @include ds.BodyBody-2Paragraph;
  padding: 16px;
}
.learnMoreButtonWarpper {
  padding: 8px;
  display: flex;
  justify-content: flex-end;
}
.tooltipDivider {
  margin: 0px !important;
}
