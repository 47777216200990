@use 'designSystem2022.module' as ds;
@use 'mixins';

.helpButton {
  @include mixins.unstyled-button;
  @include ds.BodyBody-1Medium;
  cursor: pointer;
  padding: var(--spacing-2);
  color: var(--text-light-medium-emphasis);
  transition: 0.3s ease;

  &.dark {
    color: var(--text-dark-medium-emphasis);
  }
}
